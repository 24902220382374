import React, { HTMLProps, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '~/components/buttons/Button/Button';
import Modal from '~/components/Modal/Modal';
import AuthorLayout from '~/containers/AuthorLayout/AuthorLayout';

import './Dots.scss';

interface DotsProps extends HTMLProps<HTMLDivElement> {
  onClickLeft?: () => void;
  onClickRight?: () => void;
}

const Dots: React.FC<DotsProps> = ({ onClickLeft, onClickRight, ...restParams }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const handleModalVisible = useCallback(() => {
    setModalVisible(!modalVisible);
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <div {...restParams} className="dots">
        <div onClick={onClickLeft} className="inactive-dot"></div>
        <div onDoubleClick={handleModalVisible} className="active-dot"></div>
        <div onClick={onClickRight} className="inactive-dot"></div>
      </div>
      <Modal
        title={t('home:appCreator')}
        content={<AuthorLayout />}
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        footer={[
          <Button title={t('home:close')} color="warning" size="s" rounded onClick={() => setModalVisible(false)} />,
        ]}
      />
    </>
  );
};

export default Dots;
