import React, { HTMLProps } from 'react';
import cn from 'classnames';

import './Banner.scss';

export interface BannerProps extends HTMLProps<HTMLDivElement> {}

const Banner: React.FC<BannerProps> = ({ children, className, ...restParams }) => (
  <div className={cn(className, 'banner')} {...restParams}>
    {children}
  </div>
);

export default Banner;
