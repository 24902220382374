import React, { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import Banner, { BannerProps } from '~/components/Banner/Banner';
import Image from '~/components/Image/Image';
import applePicture from '~/assets/images/home/apple.png';
import playStorePicture from '~/assets/images/home/play-store.png';

import './MobileAppBanner.scss';

interface MobileAppBannerProps extends BannerProps {
  image: string;
}

const MobileAppBanner: React.FC<MobileAppBannerProps> = ({ className, image }) => {
  const { t } = useTranslation();

  const storesPictures = useMemo(
    () => [
      {
        image: applePicture,
        link: '',
      },
      {
        image: playStorePicture,
        link: 'https://play.google.com/store/apps/details?id=com.tetratech.lesartisans',
      },
    ],
    [],
  );

  return (
    <Banner style={{ backgroundImage: `url(${image})` }} className={cn(className, 'mobile-app-banner')}>
      <div className="banner-title-container">
        <Title className="mobile-app-banner-title" weight={FontWeight.BOLD}>
          {t('home:downloadMobileApp')}
        </Title>
      </div>
      <Row className="mobile-stores-container">
        {storesPictures.map((store, idx) => (
          <Image
            onClick={() => window.open(store.link.toLowerCase(), '_blank')}
            className="mobile-store-image"
            key={idx}
            image={store.image}
            height={90}
          />
        ))}
      </Row>
    </Banner>
  );
};

export default MobileAppBanner;
