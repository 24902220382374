import { message } from 'antd';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import UploadField from '~/components/fields/UploadField/UploadField';
import useGetUser from '~/hooks/useGetUser';
import { UsersService } from '~/services/UsersService';
import emptyAvatar from '~/assets/images/register/avatar.png';

import './UpdateProfilePictureForm.scss';

interface UpdateProfilePictureFormProps {}

const UpdateProfilePictureForm: React.FC<UpdateProfilePictureFormProps> = ({}) => {
  const { t } = useTranslation();
  const validate = Yup.object({
    picture: Yup.string().required(t('register:pictureRequired')).nullable(),
  });
  const user = useGetUser() as any;
  const saveNewImageUrl = useCallback(async picture => {
    try {
      await UsersService.updateProfilePicture(picture);
    } catch (error: any) {
      message.error(error.response?.data?.statusText || t('global:generalResponse'), 4);
    }
  }, []);

  console.log(user);

  const handleGetPicture = useCallback(
    (picture: string): string => {
      switch (picture) {
        case '':
          return emptyAvatar;
        case null:
          return emptyAvatar;
        case undefined:
          return emptyAvatar;
        default:
          return user.picture;
      }
    },
    [user],
  );

  return (
    <Formik
      onSubmit={values => {}}
      initialValues={{
        picture: '',
      }}
      validationSchema={validate}
    >
      {formik => (
        <Form>
          <UploadField
            formProps={formik}
            name="picture"
            defaultAvatar={handleGetPicture(user?.picture)}
            title={t('register:addPhoto')}
            saveNewImageUrl={saveNewImageUrl}
          />
        </Form>
      )}
    </Formik>
  );
};

export default UpdateProfilePictureForm;
