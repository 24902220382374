import { useEffect, useMemo, useState } from 'react';

import { User } from '~/entities/user';
import { LOGIN_ROUTE } from '~/routing/routes';
import { UsersService } from '~/services/UsersService';

function useGetUser(): Maybe<User> {
  const [user, setUser] = useState<Maybe<User>>();

  const accessToken = useMemo(() => window.localStorage.getItem('accessToken'), []);

  useEffect(() => {
    if (!accessToken) {
      window.location.href = LOGIN_ROUTE();
      return;
    }

    UsersService.getUser().then(fetchedUser => {
      if (!fetchedUser) {
        window.location.href = LOGIN_ROUTE();
        return;
      }
      setUser(fetchedUser);
    });
  }, [accessToken]);

  return user;
}

export default useGetUser;
