import React, { HTMLProps } from 'react';
import cn from 'classnames';
import VerificationInput from 'react-verification-input';

import './VerificationField.scss';

interface VerificationFieldProps extends Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'value'> {
  value: string;
  onChange: (value: string) => void;
}

const VerificationField: React.FC<VerificationFieldProps> = ({ onChange, value, disabled }) => (
  <div className={cn('verification-field')}>
    <VerificationInput
      onChange={onChange}
      autoFocus
      value={value}
      validChars={'/0-9/'}
      inputProps={{ disabled }}
      classNames={{
        container: 'container',
        character: 'character',
        characterInactive: 'character--inactive',
        characterSelected: 'character--selected',
      }}
    />
  </div>
);
export default VerificationField;
