import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import * as Yup from 'yup';

import { Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import { ContactFormProps } from '~/components/forms/ContactForm/ContactForm';
import TextField from '~/components/fields/TextField/TextField';
import Button from '~/components/buttons/Button/Button';

import './UpdatePasswordForm.scss';

interface UpdatePasswordFormProps extends ContactFormProps {}

const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const validate = Yup.object({
    oldPassword: Yup.string().required(t('myAccount:oldPasswordRequired')),
    newPassword: Yup.string().required(t('myAccount:newPasswordRequired')).min(8, t('register:minPasswordLength')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('register:confirmPasswordMatch'))
      .required(t('myAccount:confirmNewPasswordRequired')),
  });
  return (
    <div className="updatePasswordForm">
      <div className="updatePasswordForm-header">
        <Title level={3} weight={FontWeight.NORMAL} className="updatePasswordForm-header-title">
          {t('myAccount:updatePassword')}
        </Title>
      </div>
      <div className="updatePasswordForm-content">
        <Formik
          onSubmit={values => {
            handleSubmit(values);
          }}
          initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={validate}
        >
          {formik => (
            <Form>
              <TextField
                height={50}
                className="text-field-updatePasswordForm"
                type="password"
                label={t('myAccount:oldPassword')}
                placeholder={t('myAccount:oldPassword')}
                name="oldPassword"
              />
              <TextField
                height={50}
                className="text-field-updatePasswordForm"
                type="password"
                label={t('myAccount:newPassword')}
                placeholder={t('myAccount:newPassword')}
                name="newPassword"
              />
              <TextField
                height={50}
                className="text-field-updatePasswordForm"
                type="password"
                label={t('myAccount:confirmPassword')}
                placeholder={t('myAccount:confirmPassword')}
                name="confirmPassword"
              />

              <Row className="mt-4" justify="center">
                <Button
                  className="updatePasswordForm-submit-button"
                  title={t('myAccount:update')}
                  rounded
                  color="warning"
                  type="submit"
                />
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdatePasswordForm;
