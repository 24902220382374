import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Row } from 'antd';
import { useHistory } from 'react-router';

import { SessionsService } from '~/services/SessionsService';
import Logo from '~/containers/Logo/Logo';
import LoginForm from '~/components/forms/LoginForm/LoginForm';
import loginFormClientBackground from '~/assets/images/login/loginFormBackgroundAsClient.png';
import loginFormCraftsmanBackground from '~/assets/images/login/loginFormBackgroundAsCraftsman.png';
import { MY_ACCOUNT_ROUTE } from '~/routing/routes';

import './Login.scss';

const Login: React.FC = () => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState(0);
  const { push: redirect } = useHistory();

  const handleLogin = useCallback(async formValues => {
    const loadingMessageKey = 'loadingMessage';
    message.loading({
      key: loadingMessageKey,
      content: t('global:formSubmissionLoading'),
    });
    try {
      await SessionsService.login('+225' + formValues.phone, formValues.password);
      message.destroy(loadingMessageKey);
      redirect(MY_ACCOUNT_ROUTE());
    } catch (error: any) {
      message.destroy(loadingMessageKey);
      message.error(error.response?.data?.statusText || t('global:generalResponse'), 4);
    }
  }, []);

  const loginFormContent = useMemo(
    () => [
      {
        subtitle: t('login:findTheCraftsmanYouNeed'),
        backgroundImage: loginFormClientBackground,
      },
      {
        subtitle: t('login:quicklyFindMissions'),
        backgroundImage: loginFormCraftsmanBackground,
      },
    ],
    [],
  );

  useEffect(() => {
    if (!loginFormContent[0]) return;
    setSelectedItem(0);
  }, [loginFormContent]);

  const handleChangeLoginContent = useCallback(
    (index: number) => {
      if (!loginFormContent?.length) return;
      setSelectedItem(index);
    },
    [loginFormContent],
  );

  return (
    <div className="login">
      <div className="login-header">
        <Row justify="center">
          <Logo />
        </Row>
      </div>
      <div className="login-content">
        <LoginForm
          title={t('login:signIntoYourAccount')}
          subtitle={loginFormContent[selectedItem].subtitle}
          backgroundImage={loginFormContent[selectedItem].backgroundImage}
          handleSubmit={handleLogin}
          handleChangeLoginContent={handleChangeLoginContent}
          selectedItem={selectedItem}
        />
      </div>
    </div>
  );
};

export default Login;
