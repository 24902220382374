import cn from 'classnames';
import React, { HTMLProps } from 'react';

import './NavigationButton.scss';

interface NavigationButtonProps extends HTMLProps<HTMLDivElement> {}

const NavigationButton: React.FC<NavigationButtonProps> = ({ children, className, ...props }) => (
  <div className={cn('navigation-button', className)} {...props}>
    {children}
  </div>
);

export default NavigationButton;
