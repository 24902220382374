import cn from 'classnames';
import { HTMLProps } from 'react';

import './Card.scss';

interface CardSubComponents {
  Head: React.FC<CardProps>;
  Foot: React.FC<CardProps>;
}

export interface CardProps extends HTMLProps<HTMLDivElement> {
  rounded?: boolean;
  slightlyRounded?: boolean;
}

const Card: React.FC<CardProps> & CardSubComponents = ({ children, className, rounded, slightlyRounded, ...props }) => (
  <div className={cn(className, 'card', { rounded }, { slightlyRounded })} {...props}>
    {children}
  </div>
);

const Head: React.FC<CardProps> = ({ children, className, rounded, ...props }) => (
  <div className={cn(className, 'card-head')} {...props}>
    {children}
  </div>
);

const Foot: React.FC<CardProps> = ({ children, className, rounded, ...props }) => (
  <div className={cn(className, 'card-foot')} {...props}>
    {children}
  </div>
);

Card.Head = Head;

Card.Foot = Foot;

export default Card;
