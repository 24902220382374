import UploadImageResponse from '~/entities/responses/uploadImageResponse';
import { UploadImagesResponse } from '~/entities/responses/uploadImagesResponse';
import { APIService } from '~/services/ApiService';

interface ImagesBody {
  image1: string;
  image2: string;
  image3: string;
  image4: string;
  image5: string;
}

export const UploadImageService = {
  async uploadImage(file: any): Promise<UploadImageResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', String(process.env.REACT_APP_UPLOAD_PRESET));
    formData.append('api_key', String(process.env.REACT_APP_API_KEY));
    const res = await APIService.call(
      'POST',
      `/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
      formData,
      undefined,
      undefined,
      true,
    );
    return res.data;
  },
  async uploadImages(body: ImagesBody): Promise<UploadImagesResponse> {
    const res = await APIService.call('POST', '/users/upload-images', body);
    return res.data;
  },
};
