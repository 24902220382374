import { Session } from '~/entities/session';
import { APIService } from '~/services/ApiService';

export const SessionsService = {
  async login(phone: string, password: string): Promise<Maybe<Session>> {
    const res = await APIService.call('POST', '/auth/login', { phone, password });
    localStorage.setItem('accessToken', res.data.token);
    localStorage.setItem('sessionId', res.data.sessionId);
    return res.data;
  },

  async logout(): Promise<void> {
    const sessionId = localStorage.getItem('sessionId');
    await APIService.call('DELETE', `/auth/logout/${sessionId}`);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('sessionId');
    window.location.href = '/';
  },

  isLoggedIn() {
    return !!localStorage.getItem('accessToken');
  },
};
