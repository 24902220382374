import ReactGA from 'react-ga';

interface GaProps {
  pathname: string;
}

export default function GoogleAnalytics(pathname: GaProps['pathname']) {
  ReactGA.initialize(String(process.env.REACT_APP_GA_KEY));

  let pageView: string;

  if (pathname === '*') pageView = '/not-found';
  else pageView = pathname;

  ReactGA.pageview(pageView);
}
