import { Col, Row } from 'antd';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

import Loader from '~/components/loaders/Loader/Loader';
import { Title } from '~/components/Typo/Typo';
import CraftmenDirectoryCard from '~/containers/CraftmenDirectoryCard/CraftmenDirectoryCard';
import { CraftsmenService } from '~/services/CraftsmenService';
import { FontWeight, TextVariants } from '~/theme/constants';

import './CraftmenDirectory.scss';

const CraftmenDirectory: React.FC = () => {
  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);
  const [craftsmen, setCraftsmen] = useState<ReactElement[]>([]);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const getCraftsmen = useCallback(async () => {
    try {
      const res = await CraftsmenService.getLastRegisteredCraftsmen(500);
      if (res.success) {
        const data = res.craftsmen;
        const slice = data.slice(offset, offset + perPage);
        const items = slice.map((item, idx) => (
          <Col key={idx} lg={8} sm={12} xs={24}>
            <CraftmenDirectoryCard
              id={item._id}
              fullName={`${item.firstName} ${item.lastName}`}
              avatar={item.picture as any}
              trades={[item.job as any]}
              localisation={`Abidjan, ${item.town}`}
              biography={item.biography as any}
            />
          </Col>
        ));
        setCraftsmen(items);
        setPageCount(Math.ceil(data.length / perPage));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [offset, perPage]);

  const handlePageClick = useCallback(
    e => {
      const selectedPage = e.selected;
      setOffset(selectedPage + 1);
    },
    [setOffset],
  );

  useEffect(() => {
    getCraftsmen();
  }, [offset, setOffset]);
  return (
    <div className="craftmen-directory">
      <div className="craftmen-directory-header">
        <Title className="craftmen-directory-title" weight={FontWeight.BOLD} variant={TextVariants.PRIMARY}>
          {t('craftmenDirectory:craftmenDirectoryTitle')}
        </Title>
      </div>
      <div className="craftmen-directory-content">
        {loading ? (
          <Row justify="center">
            <Loader />
          </Row>
        ) : (
          <>
            <Row>{craftsmen}</Row>
            <Row justify="center" className="mt-8">
              <ReactPaginate
                previousLabel={'Prev'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={perPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default CraftmenDirectory;
