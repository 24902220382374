import React, { ReactElement } from 'react';
import { Divider, message, Row, Tooltip } from 'antd';
import cn from 'classnames';
import { PoweroffOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Text, Title } from '~/components/Typo/Typo';
import { User, UserCategory } from '~/entities/user';
import { FontWeight, TextVariants } from '~/theme/constants';
import { SessionsService } from '~/services/SessionsService';
import UpdateProfilePictureForm from '~/components/forms/UpdateProfilePictureForm/UpdateProfilePictureForm';
import Loader from '~/components/loaders/Loader/Loader';

import './Sidebar.scss';

interface InfosProps {
  icon: ReactElement;
  itemEntitled: string;
  counter?: number;
}

interface SidebarProps {
  user: User;
  infos?: InfosProps[];
  handleChangeAccountView: (index: number) => void;
  selectedItem: number;
  loader?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ user, infos, handleChangeAccountView, selectedItem, loader }) => {
  const { t } = useTranslation();
  return (
    <div className="sidebar">
      {loader ? (
        <Row className="sidebarLoaderWrapper" justify="center">
          <Loader />
        </Row>
      ) : (
        <div className="sidebar-header">
          <UpdateProfilePictureForm />
          <div className="sidebar-personals-infos-details">
            <Title
              className="sidebar-personals-infos-fullName"
              level={3}
              weight={FontWeight.NORMAL}
              variant={TextVariants.PRIMARY}
            >
              {`${user?.firstName} ${user?.lastName}`}
            </Title>
            <Text size="p" className="sidebar-personals-infos-email" weight={FontWeight.NORMAL}>
              {user?.email}
            </Text>
            <br />
            <Text size="p" className="sidebar-personals-infos-type" weight={FontWeight.NORMAL}>
              {'Compte '} {user?.userType === UserCategory.CUSTOMER ? 'client' : 'artisan'}
            </Text>
          </div>
        </div>
      )}
      <Divider plain />
      <div className="infos-menu-block">
        <Title className="infos-menu-title" level={4} weight={FontWeight.NORMAL}>
          {t('myAccount:account').toUpperCase()}
        </Title>
        <div className="infos-menu-group">
          {infos?.map((info, idx) => (
            <div
              key={idx}
              className={cn('infos-menu-item', { focus: selectedItem === idx })}
              onClick={() => {
                if (user?.userType !== UserCategory.CRAFTSMAN && idx === 3) {
                  message.info(
                    'Vous devez être un artisan pour pouvoir ajouter des photos de présentation sur votre profile',
                    5,
                  );
                  return;
                }
                handleChangeAccountView(idx);
              }}
            >
              {info.icon}
              <Text className="infos-item-entitled" size="p" variant={TextVariants.PRIMARY}>
                {info.itemEntitled}
              </Text>
              {typeof info.counter === 'number' && (
                <Tooltip overlay={t('myAccount:totalOfAllNotifs')}>
                  <span className="infos-items-notification-counter">{info.counter}</span>
                </Tooltip>
              )}
            </div>
          ))}
        </div>
      </div>
      <Divider plain />
      <div className="sidebar-foot">
        <div className="sidebar-foot-imitation-button" onClick={SessionsService.logout}>
          <PoweroffOutlined className="imitation-button-icon" />
          <Text className="imitation-button-entitled" size="p" variant={TextVariants.PRIMARY}>
            {t('myAccount:signOut')}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
