import { BaseModel } from '~/entities/baseModel';
import { Image } from '~/entities/responses/uploadImagesResponse';

export enum UserCategory {
  CUSTOMER = 'customer',
  CRAFTSMAN = 'craftsman',
}

export interface User extends BaseModel {
  firstName: string;
  lastName: string;
  email: string;
  picture?: string;
  phone?: string;
  job?: string;
  town?: string;
  city?: string;
  userType: UserCategory;
  biography?: string;
  lastLogin?: Date;
  emailVerified: boolean;
  images?: Image;
  comments: Comment[];
}

export interface Comment {
  comment: string;
  author: Author;
  createdAt: Date;
}

export interface Author {
  firstName: string;
  lastName: string;
  picture?: string;
}
