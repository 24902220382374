import englandIcon from '~/assets/icons/flags/england.svg';
import franceIcon from '~/assets/icons/flags/france.svg';

export type Language = { label: string; icon: string };

export const languages: Language[] = [
  {
    label: 'en',
    icon: englandIcon,
  },
  {
    label: 'fr',
    icon: franceIcon,
  },
];

export const getLanguage = (label: string) => languages.find(language => language.label === label) || languages[0];
