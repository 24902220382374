import React, { useCallback, useState } from 'react';
import { Button, Col, Drawer, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import LanguageSelect from '~/components/forms/LanguageSelect/LanguageSelect';
import Navbar from '~/components/Navbar/Navbar';
import logo from '~/assets/logos/logo-mobile-black.png';
import { NavigationBarProps } from '~/containers/Header/NavigationBar/NavigationBar';
import { HOME_ROUTE } from '~/routing/routes';

import './NavigationDrawer.scss';

const NavigationDrawer: React.FC<NavigationBarProps> = ({ items }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = useCallback(() => {
    setVisible(true);
  }, []);

  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <div className="navigation-drawer">
      <Row>
        <Col span={8}>
          <Button className="navigation-drawer-menu-button" icon={<MenuOutlined />} onClick={showDrawer} />
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          <Link to={HOME_ROUTE()} className="navigation-drawer-link">
            <img className="navigation-drawer-logo" src={logo} alt="artisan logo" />
          </Link>
        </Col>
        <Col span={8}></Col>
      </Row>
      <Drawer placement="left" closable onClose={onClose} visible={visible}>
        <div className="drawer-menu-header">
          <div className="drawer-menu-header-left">
            <LanguageSelect />
          </div>
        </div>
        <Navbar>
          {items.map(({ label, active, link }, idx) => (
            <div key={idx} className="navigation-drawer-item-container">
              <Navbar.Item key={link} link={link} active={active}>
                {label}
              </Navbar.Item>
            </div>
          ))}
        </Navbar>
        <div className="drawer-menu-footer"></div>
      </Drawer>
    </div>
  );
};

export default NavigationDrawer;
