import { Col, Row } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph, Title } from '~/components/Typo/Typo';
import LanguageSelect from '~/components/forms/LanguageSelect/LanguageSelect';
import logo from '~/assets/logos/artisan-logo.png';
import { FontWeight, TextVariants } from '~/theme/constants';
import FooterNav from '~/containers/FooterNav/FooterNav';
import { SessionsService } from '~/services/SessionsService';
import {
  CONTACT_ROUTE,
  FIND_CRAFTMAN_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  MY_ACCOUNT_ROUTE,
  OUR_SERVICES_ROUTE,
} from '~/routing/routes';
import applePicture from '~/assets/images/home/apple.png';
import playStorePicture from '~/assets/images/home/play-store.png';
import Image from '~/components/Image/Image';
import Modal from '~/components/Modal/Modal';
import RegistrationLayout from '~/containers/RegistrationLayout/RegistrationLayout';
import Button from '~/components/buttons/Button/Button';

import './Footer.scss';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const getAuthRoute = function () {
    if (SessionsService.isLoggedIn()) {
      return ['myAccount', MY_ACCOUNT_ROUTE()];
    }
    return ['login', LOGIN_ROUTE()];
  };
  const [modalVisible, setModalVisible] = useState(false);
  const handleModalVisible = useCallback(() => {
    setModalVisible(!modalVisible);
  }, []);
  const menuItems = useMemo(
    () =>
      [
        ['home', HOME_ROUTE()],
        ['ourServices', OUR_SERVICES_ROUTE()],
        ['findCraftman', FIND_CRAFTMAN_ROUTE()],
        ['contact', CONTACT_ROUTE()],
        getAuthRoute(),
      ].map(([translationKey, route], idx) => ({
        key: idx,
        label: t(`header:navigation:${translationKey}`),
        link: route,
        active: location.pathname === route,
      })),
    [location.pathname, getAuthRoute()],
  );
  const storesPictures = useMemo(
    () => [
      {
        image: applePicture,
        link: '#',
      },
      {
        image: playStorePicture,
        link: 'https://play.google.com/store/apps/details?id=com.tetratech.lesartisans',
      },
    ],
    [],
  );
  return (
    <>
      <div className="footer">
        <div className="footer-wrapper">
          <Row justify="space-between">
            <Col lg={8}>
              <img className="footer-wrapper-logo" src={logo} alt="" />
              <div className="footer-wrapper-infos">
                <Paragraph className="wrapper-infos-details">10 BP 03 Abidjan 10</Paragraph>
                <Paragraph className="wrapper-infos-details">Abidjan - Port Bouet, Côte d'Ivoire</Paragraph> <br />
                <Paragraph className="wrapper-infos-details">Phone: +2250707732264</Paragraph>
                <Paragraph className="wrapper-infos-details">E-mail: contact@lesartisans.ci</Paragraph>
              </div>
            </Col>
            <Col lg={8}>
              <Title
                className="footer-wrapper-useful-links"
                level={3}
                weight={FontWeight.MEDIUM}
                variant={TextVariants.WHITE}
              >
                {t('footer:usefulLinks')}
              </Title>
              <div className="footer-useful-links">
                <FooterNav items={menuItems} />
              </div>
            </Col>
            <Col lg={8}>
              <Title
                className="footer-wrapper-useful-links"
                level={3}
                weight={FontWeight.MEDIUM}
                variant={TextVariants.WHITE}
              >
                {t('footer:downloadMobileApp')}
              </Title>
              <Row className="footer-mobile-stores-container">
                {storesPictures.map((store, idx) => (
                  <Image
                    onClick={() => window.open(store.link.toLowerCase(), '_blank')}
                    className="footer-mobile-store-image"
                    key={idx}
                    image={store.image}
                    height={90}
                  />
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="extra-footer">
        &copy; Copyrights {new Date().getFullYear()} Les artisans - {t('footer:allRightsReserved')}. Builded by{' '}
        <a
          className="footer-author-external-links"
          href="https://www.linkedin.com/in/jude-ette-74320315b/"
          target="_blank"
        >
          @iTSuperman
        </a>
      </div>

      <div className="footer-floating-button">
        <LanguageSelect className="floating-button-languageSelect" />
      </div>
      {!SessionsService.isLoggedIn() && (
        <div className="footer-floating-register" onClick={handleModalVisible}>
          {t('footer:registerNow')}
        </div>
      )}
      <Modal
        title={'Inscription'}
        content={<RegistrationLayout />}
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        footer={[
          <Button title={t('home:close')} color="warning" size="s" rounded onClick={() => setModalVisible(false)} />,
        ]}
      />
    </>
  );
};

export default Footer;
