import React, { HTMLProps } from 'react';
import { Form, Formik } from 'formik';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import TextField from '~/components/fields/TextField/TextField';
import Button from '~/components/buttons/Button/Button';
import MessageField from '~/components/fields/MessageField/MessageField';

import './ContactForm.scss';

export interface ContactFormProps extends HTMLProps<HTMLFormElement> {
  handleSubmit: (values: any) => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const validate = Yup.object({
    lastName: Yup.string().required(t('home:contact:contactForm:validationMessages:lastName')),
    firstName: Yup.string().required(t('home:contact:contactForm:validationMessages:firstName')),
    email: Yup.string()
      .email(t('home:contact:contactForm:validationMessages:email'))
      .required(t('home:contact:contactForm:validationMessages:emailRequired')),
    message: Yup.string().required(t('home:contact:contactForm:validationMessages:message')),
  });

  return (
    <Formik
      onSubmit={values => {
        handleSubmit(values);
      }}
      initialValues={{ lastName: '', firstName: '', email: '', message: '' }}
      validationSchema={validate}
    >
      {formik => (
        <Form className="contact-form">
          <Row>
            <Col lg={12} md={12} sm={24} xs={24}>
              <TextField
                height={70}
                className="text-field-contact-form"
                label={t('home:contact:contactForm:lastName')}
                name="lastName"
                type="text"
                placeholder={t('home:contact:contactForm:enterYourLastName')}
              />
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <TextField
                height={70}
                className="text-field-contact-form"
                label={t('home:contact:contactForm:firstName')}
                name="firstName"
                type="text"
                placeholder={t('home:contact:contactForm:enterYourFirstName')}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={24} md={12} sm={24} xs={24}>
              <TextField
                height={70}
                className="text-field-contact-form"
                label={'Email'}
                name="email"
                type="email"
                placeholder={t('home:contact:contactForm:enterYourEmail')}
              />
            </Col>
            <Col lg={24} md={12} sm={24} xs={24}>
              <MessageField
                className="text-field-contact-form"
                label={t('home:contact:contactForm:message')}
                name="message"
                placeholder={t('home:contact:contactForm:enterYourMessage')}
                height={150}
              />
            </Col>
          </Row>
          <Row className="mt-2" justify="center">
            <Button title={t('home:contact:contactForm:send')} rounded type="submit" color="warning" size="m" />
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default ContactForm;
