import React, { HTMLProps } from 'react';
import { ErrorMessage, useField } from 'formik';
import cn from 'classnames';

import './OptionField.scss';

interface Option {
  value: string;
  text: string;
}

export interface OptionFieldProps extends Omit<HTMLProps<HTMLSelectElement>, 'label' | 'name'> {
  label?: string;
  name: string;
  optionValues: Option[];
}

const OptionField: React.FC<OptionFieldProps> = ({
  placeholder,
  disabled,
  label,
  className,
  name,
  height,
  optionValues,
  ...restParams
}) => {
  const [field, meta] = useField(name);

  return (
    <div className={cn(className, 'option-field')}>
      {label && (
        <label htmlFor={field.name} className="option-field-label">
          {label}
        </label>
      )}
      <select
        className={cn({ 'form-control': meta.touched && meta.error }, { 'is-invalid': meta.touched && meta.error })}
        {...restParams}
        {...field}
        style={{ height }}
        placeholder={placeholder}
        disabled={disabled}
      >
        {optionValues.map((option, index) => (
          <option key={index} value={option.value} selected={option.value === ''}>
            {option.text}
          </option>
        ))}
      </select>
      <ErrorMessage name={field.name} component="div" className="text-danger" />
    </div>
  );
};
export default OptionField;
