import React, { HTMLProps } from 'react';

import './Content.scss';

interface ContentProps extends HTMLProps<HTMLDivElement> {}

const Content: React.FC<ContentProps> = ({ children, ...otherProps }) => (
  <div id="content" {...otherProps}>
    {children}
  </div>
);

export default Content;
