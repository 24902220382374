import { Col, Divider, message, Popover, Row } from 'antd';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ImageCapture from 'react-image-data-capture';

import OptionField from '~/components/fields/OptionField/OptionField';
import Button from '~/components/buttons/Button/Button';
import TextField from '~/components/fields/TextField/TextField';
import { Text, Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import { ContactFormProps } from '~/components/forms/ContactForm/ContactForm';
import UploadField from '~/components/fields/UploadField/UploadField';
import { phoneRegExp } from '~/utils/phone';
import Modal from '~/components/Modal/Modal';
import Image from '~/components/Image/Image';
import avatar from '~/assets/images/register/emptyAvatar.svg';
import { UploadImageService } from '~/services/UploadImageService';

import './RegisterCraftsmanForm.scss';

interface RegisterCraftsmanFormProps extends ContactFormProps {
  handleChangeRegisterForm: (index: number) => void;
}

const RegisterCraftsmanForm: React.FC<RegisterCraftsmanFormProps> = ({ handleSubmit, handleChangeRegisterForm }) => {
  const { t } = useTranslation();
  const validate = Yup.object({
    lastName: Yup.string().required(t('home:contact:contactForm:validationMessages:lastName')),
    firstName: Yup.string().required(t('home:contact:contactForm:validationMessages:firstName')),
    email: Yup.string().email(t('home:contact:contactForm:validationMessages:email')).nullable(),
    phone: Yup.string().required(t('register:phoneRequired')).matches(phoneRegExp, t('register:incorrectNumberFormat')),
    password: Yup.string().required(t('register:passwordRequired')).min(8, t('register:minPasswordLength')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('register:confirmPasswordMatch'))
      .required(t('register:confirmPasswordRequired')),
    job: Yup.string().required(t('register:jobRequired')),
    town: Yup.string().required(t('register:townRequired')),
    picture: Yup.string().nullable(),
  });
  const [modalVisible, setModalVisible] = useState(false);
  const handleModalVisible = useCallback(() => {
    setModalVisible(!modalVisible);
  }, []);

  const [imgFile, setImgFile] = useState('');
  const [imgSrc, setImgSrc] = useState('');
  const [profilePic, setProfilePic] = useState(avatar);

  const onCapture = async (imageData: any) => {
    setImgSrc(imageData.webP);
    setImgFile(imageData.file);
    const file: File = imageData.file;
    try {
      const res = await UploadImageService.uploadImage(file);
      if (res.secure_url) {
        setImgSrc(res.secure_url);
        setProfilePic(res.secure_url);
      }
    } catch (error) {
      return message.error(t('register:uploadImageErrorMessage'), 3);
    }
  };

  const showSelectedImageGallery = useCallback(async img => {
    setProfilePic(img);
  }, []);
  const onError = useCallback(error => {
    console.log(error);
  }, []);

  const config = useMemo(() => ({ video: true }), []);
  const formData = new FormData();
  formData.append('file', imgFile);
  return (
    <div className="registerCraftsmanForm">
      <Row justify="center">
        <Col lg={9} sm={24} xs={24}>
          <div className="registerCraftsmanForm-wrapper">
            <Title className="registerCraftsmanForm-title" weight={FontWeight.NORMAL} level={3}>
              {t('register:createYourAccountNow')}
            </Title>
            <Formik
              onSubmit={values => {
                handleSubmit({ ...values, picture: imgSrc });
              }}
              initialValues={{
                lastName: '',
                firstName: '',
                email: '',
                phone: '',
                job: '',
                town: '',
                picture: '',
                password: '',
                confirmPassword: '',
                userType: 'craftsman',
              }}
              validationSchema={validate}
            >
              {formik => (
                <Form>
                  <Row justify="center">
                    <Row justify="center" className="mb-3">
                      <Image
                        className="register-craftsman-btn"
                        onClick={handleModalVisible}
                        image={profilePic}
                        height={70}
                        width={70}
                      />
                      <Text className="mt-5 ml-3" size="p">
                        Ajouter une photo de profil
                      </Text>
                    </Row>
                    <Modal
                      title={"Choisissez la source de l'image"}
                      content={
                        <Row justify="center">
                          <div className="tac mr-2">
                            <Text size="p" weight={FontWeight.MEDIUM}>
                              GALÉRIE
                            </Text>
                            <UploadField
                              formProps={formik}
                              className="text-field-registerCraftsmanForm"
                              name="picture"
                              title={t('register:addPhoto')}
                              saveNewImageUrl={showSelectedImageGallery}
                            />
                          </div>
                          <div className="tac ml-2">
                            <Text size="p" weight={FontWeight.MEDIUM}>
                              CAMÉRA
                            </Text>
                            <br />
                            <ImageCapture
                              onCapture={onCapture}
                              onError={onError}
                              width={200}
                              userMediaConfig={config}
                              className="image-capture"
                            />
                            {imgSrc && (
                              <div>
                                <div>Image capturée:</div>
                                <img src={imgSrc} alt="captured-img" />
                              </div>
                            )}
                          </div>
                        </Row>
                      }
                      isVisible={modalVisible}
                      onClose={() => setModalVisible(false)}
                      footer={[
                        <Button
                          title={t('home:close')}
                          color="warning"
                          size="s"
                          rounded
                          onClick={() => setModalVisible(false)}
                        />,
                      ]}
                    />
                  </Row>
                  <Row>
                    <Col lg={12} xs={24}>
                      <TextField
                        height={50}
                        className="text-field-registerCraftsmanForm"
                        name="lastName"
                        type="text"
                        placeholder={t('register:lastName')}
                      />
                    </Col>
                    <Col lg={12} xs={24}>
                      <TextField
                        height={50}
                        className="text-field-registerCraftsmanForm"
                        name="firstName"
                        type="text"
                        placeholder={t('register:firstName')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} xs={24}>
                      {/* <Popover
                        title={t('register:warning')}
                        content={t('register:youWillReceiveVerifCode')}
                        trigger="click"
                      > */}
                      <TextField
                        height={50}
                        className="text-field-registerCraftsmanForm"
                        name="email"
                        type="email"
                        placeholder={'Email'}
                      />
                      {/* </Popover> */}
                    </Col>
                    <Col lg={12} xs={24}>
                      <Popover
                        title={t('register:warning')}
                        content={t('register:pleaseRecpectTheNew')}
                        trigger="click"
                      >
                        <TextField
                          height={50}
                          className="text-field-registerCraftsmanForm"
                          name="phone"
                          type="tel"
                          placeholder={t('register:phone')}
                        />
                      </Popover>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} xs={24}>
                      <OptionField
                        height={50}
                        className="text-field-registerCraftsmanForm"
                        name="job"
                        placeholder={t('register:trade')}
                        optionValues={[
                          { value: '', text: t('register:trade') },
                          { value: 'peintre', text: 'Peintre' },
                          { value: 'couvreur', text: 'Couvreur' },
                          { value: 'plombier', text: 'Plombier' },
                          { value: 'jardinier', text: 'Jardinier' },
                          { value: 'maçon', text: 'Maçon' },
                          { value: 'carreleur', text: 'Carreleur' },
                          { value: 'tapissier', text: 'Tapissier' },
                          { value: 'chauffagiste', text: 'Chauffagiste' },
                          { value: 'taxidermiste', text: 'Taxidermiste' },
                          { value: 'esthéticienne', text: 'Esthéticienne' },
                          { value: 'tailleur', text: 'Tailleur' },
                          { value: 'ébéniste', text: 'Ébéniste' },
                          { value: 'vitrailliste', text: 'Vitrailliste' },
                          { value: 'horloger', text: 'Horloger' },
                          { value: 'électricien', text: 'Électricien' },
                          { value: 'menuisier', text: 'Menuisier' },
                          { value: 'jardinier', text: 'Jardinier' },
                          { value: 'cuisinier', text: 'Cuisinier' },
                          { value: 'serrurier', text: 'Serrurier' },
                          { value: 'technicien froid et climatisation', text: 'Technicien froid et climatisation' },
                          { value: 'cordonnier', text: 'Cordonnier' },
                        ]}
                      />
                    </Col>
                    <Col lg={12} xs={24}>
                      <OptionField
                        height={50}
                        className="text-field-registerCraftsmanForm"
                        name="town"
                        placeholder={t('register:town')}
                        optionValues={[
                          // TODO: Store all its data in database
                          { value: '', text: t('register:town') },
                          { value: 'abobo', text: 'Abobo' },
                          { value: 'adjamé', text: 'Adjamé' },
                          { value: 'anyama', text: 'Anyama' },
                          { value: 'attécoubé', text: 'Attécoubé' },
                          { value: 'bingerville', text: 'Bingerville' },
                          { value: 'cocody', text: 'Cocody' },
                          { value: 'koumassi', text: 'Koumassi' },
                          { value: 'marcory', text: 'Marcory' },
                          { value: 'plateau', text: 'Plateau' },
                          { value: 'port bouët', text: 'Port bouët' },
                          { value: 'treichville', text: 'Treichville' },
                          { value: 'songon', text: 'Songon' },
                          { value: 'yopougon', text: 'Yopougon' },
                        ]}
                      />
                    </Col>
                  </Row>
                  <TextField
                    height={50}
                    className="text-field-registerCraftsmanForm"
                    name="password"
                    type="password"
                    placeholder={t('register:password')}
                  />
                  <TextField
                    height={50}
                    className="text-field-registerCraftsmanForm"
                    name="confirmPassword"
                    type="password"
                    placeholder={t('register:confirmPassword')}
                  />
                  <Row justify="center" className="registerCraftsmanForm-button-container">
                    <Button
                      className="registerCraftsmanForm-submit-button"
                      type="submit"
                      title={t('register:register')}
                      size="s"
                      color="warning"
                      rounded
                    />
                  </Row>

                  <Divider className="registerCraftsmanForm-divider" plain>
                    {t('register:or')}
                  </Divider>
                  {/* <Row justify="center">
                    <Button
                      size="s"
                      icon={<FacebookFilled className="registerCraftsmanForm-registerWithFacebook-icon" />}
                      title={'Facebook'}
                      color="blue-facebook"
                      type="button"
                      rounded
                      onClick={() => null}
                    />
                  </Row> */}
                  <Row justify="center">
                    <Button
                      title={t('register:becomeCustomer')}
                      color="light"
                      type="button"
                      rounded
                      onClick={() => handleChangeRegisterForm(0)}
                      className="registerCraftsmanForm-registerButtonTitle"
                    />
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RegisterCraftsmanForm;
