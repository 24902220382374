import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import cn from 'classnames';
import { PlusOutlined } from '@ant-design/icons';

import { TextFieldProps } from '~/components/fields/TextField/TextField';
import Image from '~/components/Image/Image';

import './UploadImageField.scss';

interface UploadImageFieldProps extends TextFieldProps {
  title?: string;
  formProps?: any;
  defaultImage?: string;
}

const UploadImageField: React.FC<UploadImageFieldProps> = ({
  className,
  name,
  disabled,
  title,
  formProps,
  defaultImage,
  ...restParams
}) => {
  const uploadButton = (
    <div className="upload-multiple-button">
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Image</div>
      </div>
    </div>
  );
  const [field, meta] = useField(name);
  const [button, setButton] = useState<ReactElement>(uploadButton);

  const handleChange = useCallback(async (event: any): Promise<any> => {
    const picture: File = event.target.files[0];
    formProps.setFieldValue(field.name, picture);
    const previewImage = URL.createObjectURL(picture);
    setButton(<Image className="resize-preview-img" image={previewImage} border="white" />);
  }, []);

  useEffect(() => {
    if (defaultImage && defaultImage !== '') {
      setButton(<Image className="resize-preview-img" image={defaultImage} border="white" />);
      formProps.setFieldValue(field.name, defaultImage);
    }
  }, [defaultImage]);

  return (
    <div className={cn(className, 'upload-image-field')}>
      <label htmlFor={field.name} className="upload-multiple-label">
        {button}
      </label>
      <input
        className={cn({ 'form-control': meta.touched && meta.error }, { 'is-invalid': meta.touched && meta.error })}
        id={field.name}
        {...restParams}
        type="file"
        disabled={disabled}
        onChange={handleChange}
        accept="image/*"
      />
      <ErrorMessage name={field.name} component="div" className="text-danger" />
    </div>
  );
};

export default UploadImageField;
