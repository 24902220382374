import { Col, Popover, Row } from 'antd';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Button from '~/components/buttons/Button/Button';
import TextField from '~/components/fields/TextField/TextField';
import { ContactFormProps } from '~/components/forms/ContactForm/ContactForm';
import { Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import { phoneRegExp } from '~/utils/phone';

import './LoginForm.scss';

interface LoginFormProps extends ContactFormProps {
  title: string;
  subtitle: string;
  backgroundImage: string;
  handleChangeLoginContent: (index: number) => void;
  selectedItem: number;
}

const LoginForm: React.FC<LoginFormProps> = ({
  handleSubmit,
  title,
  subtitle,
  backgroundImage,
  handleChangeLoginContent,
  selectedItem,
}) => {
  const { t } = useTranslation();
  const validate = Yup.object({
    phone: Yup.string().required(t('register:phoneRequired')).matches(phoneRegExp, t('register:incorrectNumberFormat')),
    password: Yup.string().required(t('login:passwordRequired')),
  });
  return (
    <div className="loginForm">
      <Row justify="center">
        {/* <Col lg={12} sm={24} xs={24}>
          <div style={{ backgroundImage: `url(${backgroundImage})` }} className="loginForm-wrapper-legend">
            <div className="loginForm-legend">
              <Title className="loginForm-title" weight={FontWeight.BOLD} level={2}>
                {title}
              </Title>
              <p className="loginForm-subtitle">{subtitle}</p>
            </div>
          </div>
        </Col> */}
        <Col lg={8} sm={24} xs={24}>
          <div className="loginForm-wrapper">
            <Title className="loginForm-title" weight={FontWeight.NORMAL} level={3}>
              {subtitle}
            </Title>
            <Formik
              onSubmit={values => {
                handleSubmit(values);
              }}
              initialValues={{ phone: '', password: '' }}
              validationSchema={validate}
            >
              {formik => (
                <Form>
                  <Popover
                    title={t('register:warning')}
                    content={'Veuillez respecter la numérotation à 10 chiffres'}
                    trigger="click"
                  >
                    <TextField
                      height={50}
                      className="text-field-login-form"
                      label={'Numéro de téléphone'}
                      name="phone"
                      type="phone"
                      placeholder={'0789463462'}
                    />
                  </Popover>

                  <TextField
                    height={50}
                    className="text-field-login-form"
                    label={t('login:password')}
                    name="password"
                    type="password"
                    placeholder={t('login:password')}
                  />
                  <Row justify="center" className="loginForm-button-container">
                    <Button
                      className="loginForm-submit-button"
                      type="submit"
                      title={t('login:login')}
                      size="s"
                      color="warning"
                      rounded
                    />
                  </Row>

                  {/* <Divider className="loginForm-divider" plain>
                    {t('login:or')}
                  </Divider>
                  <Row justify="center">
                    <Button
                      size="s"
                      className="loginForm-loginWithFacebook-button mr-1"
                      icon={<FacebookFilled className="loginForm-loginWithFacebook-icon" />}
                      title={'Facebook'}
                      color="blue-facebook"
                      type="button"
                      rounded
                      onClick={() => null}
                    />

                    <Button
                      size="s"
                      className="loginForm-loginWithFacebook-button ml-1"
                      icon={<GoogleCircleFilled className="loginForm-loginWithFacebook-icon" />}
                      title={'Google'}
                      color="outline-light"
                      type="button"
                      rounded
                      onClick={() => null}
                    />
                  </Row> */}
                  <Row justify="center">
                    {selectedItem === 0 ? (
                      <Button
                        title={t('login:loginAsCraftsman')}
                        color="light"
                        type="button"
                        rounded
                        onClick={() => handleChangeLoginContent(1)}
                        className="loginForm-loginAsButtonTitle"
                      />
                    ) : (
                      <Button
                        title={t('login:loginAsCustomer')}
                        color="light"
                        type="button"
                        rounded
                        onClick={() => handleChangeLoginContent(0)}
                        className="loginForm-loginAsButtonTitle"
                      />
                    )}
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginForm;
