import React from 'react';
import { Col, Divider, Popover, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import { ContactFormProps } from '~/components/forms/ContactForm/ContactForm';
import TextField from '~/components/fields/TextField/TextField';
import Button from '~/components/buttons/Button/Button';
import { phoneRegExp } from '~/utils/phone';

import './RegisterCustomerForm.scss';

interface RegisterCustomerFormProps extends ContactFormProps {
  handleChangeRegisterForm: (index: number) => void;
}

const RegisterCustomerForm: React.FC<RegisterCustomerFormProps> = ({ handleSubmit, handleChangeRegisterForm }) => {
  const { t } = useTranslation();
  const validate = Yup.object({
    lastName: Yup.string().required(t('home:contact:contactForm:validationMessages:lastName')),
    firstName: Yup.string().required(t('home:contact:contactForm:validationMessages:firstName')),
    email: Yup.string().email(t('home:contact:contactForm:validationMessages:email')).nullable(),
    phone: Yup.string().required(t('register:phoneRequired')).matches(phoneRegExp, t('register:incorrectNumberFormat')),
    password: Yup.string().required(t('register:passwordRequired')).min(8, t('register:minPasswordLength')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('register:confirmPasswordMatch'))
      .required(t('register:confirmPasswordRequired')),
  });
  return (
    <div className="registerCustomerForm">
      <Row justify="center">
        <Col lg={9} sm={24} xs={24}>
          <div className="registerCustomerForm-wrapper">
            <Title className="registerCustomerForm-title" weight={FontWeight.NORMAL} level={3}>
              {t('register:openYourArtisanCustomer')}
            </Title>
            <Formik
              onSubmit={values => {
                handleSubmit(values);
              }}
              initialValues={{
                lastName: '',
                firstName: '',
                email: '',
                phone: '',
                password: '',
                confirmPassword: '',
                userType: 'customer',
              }}
              validationSchema={validate}
            >
              {formik => (
                <Form>
                  <TextField
                    height={50}
                    className="text-field-registerCustomerForm"
                    name="lastName"
                    type="text"
                    placeholder={t('register:lastName')}
                  />
                  <TextField
                    height={50}
                    className="text-field-registerCustomerForm"
                    name="firstName"
                    type="text"
                    placeholder={t('register:firstName')}
                  />
                  {/* <Popover
                    title={t('register:warning')}
                    content={t('register:youWillReceiveVerifCode')}
                    trigger="click"
                  > */}
                  <TextField
                    height={50}
                    className="text-field-registerCustomerForm"
                    name="email"
                    type="email"
                    placeholder={'Email'}
                  />
                  {/* </Popover> */}
                  <Popover title={t('register:warning')} content={t('register:pleaseRecpectTheNew')} trigger="click">
                    <TextField
                      height={50}
                      className="text-field-registerCustomerForm"
                      name="phone"
                      type="tel"
                      placeholder={t('register:phone')}
                    />
                  </Popover>
                  <TextField
                    height={50}
                    className="text-field-registerCustomerForm"
                    name="password"
                    type="password"
                    placeholder={t('register:password')}
                  />
                  <TextField
                    height={50}
                    className="text-field-registerCustomerForm"
                    name="confirmPassword"
                    type="password"
                    placeholder={t('register:confirmPassword')}
                  />
                  <Row justify="center" className="registerCustomerForm-button-container">
                    <Button
                      className="registerCustomerForm-submit-button"
                      type="submit"
                      title={t('register:register')}
                      size="s"
                      color="warning"
                      rounded
                    />
                  </Row>

                  <Divider className="registerCustomerForm-divider" plain>
                    {t('register:or')}
                  </Divider>
                  {/* <Row justify="center">
                    <Button
                      size="s"
                      icon={<FacebookFilled className="registerCustomerForm-registerWithFacebook-icon" />}
                      title={'Facebook'}
                      color="blue-facebook"
                      type="button"
                      rounded
                      onClick={() => null}
                    />
                  </Row> */}
                  <Row justify="center">
                    <Button
                      title={t('register:becomeCraftsman')}
                      color="light"
                      type="button"
                      rounded
                      onClick={() => handleChangeRegisterForm(1)}
                      className="registerCustomerForm-registerButtonTitle"
                    />
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RegisterCustomerForm;
