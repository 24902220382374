import { AutoComplete } from 'antd';
import React from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { TextFieldProps } from '~/components/fields/TextField/TextField';

import './AutoCompleteField.scss';

interface AutoCompleteFieldProps extends TextFieldProps {
  options: any;
  onSelect: (value: any) => void;
}

const AutoCompleteField: React.FC<AutoCompleteFieldProps> = ({
  className,
  placeholder,
  height,
  disabled,
  label,
  name,
  options,
  onSelect,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [field] = useField(name);

  return (
    <div className={cn(className, 'autoCompleteField')}>
      {label && (
        <label htmlFor={field.name} className="autoCompleteField-label">
          {label}
        </label>
      )}
      <AutoComplete
        notFoundContent={t('findCraftman:searchCraftmanForm:noResults')}
        onSelect={onSelect}
        className="autoCompleteField-wrapper"
        options={options}
        filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
      >
        <input {...otherProps} {...field} placeholder={placeholder} disabled={disabled} style={{ height }} />
      </AutoComplete>
    </div>
  );
};

export default AutoCompleteField;
