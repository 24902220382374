import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import fr from './locales/fr.json';

const languages = ['fr', 'en'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: 'translations',
    detection: {
      lookupQuerystring: 'lng',
      order: ['querystring', 'localStorage'],
    },
    fallbackLng: 'fr',
    supportedLngs: languages,
    keySeparator: false,
    ns: ['translations'],
    react: {
      useSuspense: true,
    },
    resources: { en, fr },
    saveMissing: true,
    saveMissingTo: 'all',
  });

export default i18n;
