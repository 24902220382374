import NotificationsResponse from '~/entities/responses/notificationsResponse';
import { APIService } from '~/services/ApiService';

export const NotificationsService = {
  async getNotifications(): Promise<NotificationsResponse> {
    const page = 1;
    const limit = 30;
    const res = await APIService.call('GET', '/notifications/get', undefined, { page, limit });
    return res.data;
  },
};
