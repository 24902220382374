import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import Button from '~/components/buttons/Button/Button';
import { Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';

import './RegistrationLayout.scss';

const RegistrationLayout: React.FC = () => {
  const { push: redirect } = useHistory();
  return (
    <div className="registrationLayout">
      <Title level={4} weight={FontWeight.MEDIUM} className="registrationLayout-title">
        Je m'inscris en tant que :
      </Title>
      <Row justify="center" className="mt-4">
        <Col lg={16} xs={16} sm={16}>
          <Tooltip title="Je suis prestataire : Je cherche des travaux à réaliser">
            <Button
              onClick={() => redirect('register/' + 1)}
              className="button-register-modal"
              title="Artisan"
              color="warning"
              size="s"
              rounded
              block
            />
          </Tooltip>
        </Col>
        <Col className="mt-3" lg={16} xs={16} sm={16}>
          <Tooltip title="Je suis client : J'ai des travaux à faire réaliser">
            <Button
              onClick={() => redirect('register/' + 0)}
              className="button-register-modal"
              title="Client"
              color="warning"
              size="s"
              rounded
              block
            />
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};

export default RegistrationLayout;
