import React, { ReactElement } from 'react';
import { Modal } from 'antd';

import './Modal.scss';

interface ModalProps {
  isVisible?: boolean;
  onClose: () => void;
  title: string;
  content: ReactElement;
  footer?: ReactElement[];
}

const MainModal: React.FC<ModalProps> = ({ isVisible, onClose, title, content, footer }) => (
  <Modal title={title} visible={isVisible} onOk={onClose} onCancel={onClose} footer={footer}>
    {content}
  </Modal>
);
export default MainModal;
