import cn from 'classnames';
import { HTMLProps } from 'react';

import './Image.scss';

interface ImageProps extends HTMLProps<HTMLImageElement> {
  image?: string;
  width?: number | string;
  height?: number | string;
  rounded?: boolean;
  circle?: boolean;
  border?: 'white' | 'dark';
  onClick?: () => void;
}

const Image: React.FC<ImageProps> = ({ image, width, height, rounded, circle, style, className, border, onClick }) => (
  <div className="image">
    <img
      onClick={onClick}
      style={{ width, height, ...style }}
      className={cn(className, border && `border-${border}`, { rounded }, { circle })}
      src={image}
      alt="image"
    />
  </div>
);

export default Image;
