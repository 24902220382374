import { Col, Row } from 'antd';
import React, { useCallback, useContext } from 'react';
import { useLocation } from 'react-router';
import cn from 'classnames';

import { MY_ACCOUNT_ROUTE } from '~/routing/routes';
import { Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import NavigationBar, { NavigationBarProps } from '~/containers/Header/NavigationBar/NavigationBar';
import Logo from '~/containers/Logo/Logo';
import NavigationDrawer from '~/components/NavigationDrawer/NavigationDrawer';
import SearchHomeCraftsmanForm from '~/components/forms/SearchHomeCraftsmanForm/SearchHomeCraftsmanForm';
import { LoadingContext } from '~/contexts/LoaderContext';

import './Header.scss';
interface HeaderProps extends NavigationBarProps {
  banner?: string;
  title?: string;
  subtitle?: string;
}

const Header: React.FC<HeaderProps> = ({ items, banner, title, subtitle }) => {
  const currentLocation = useLocation();

  const { startLoading } = useContext(LoadingContext);

  const handleShowHeaderBackgroundImage = useCallback(() => {
    if (!banner) return;
    if (currentLocation.pathname !== MY_ACCOUNT_ROUTE() && !currentLocation.pathname.includes('/craftsman')) {
      return { backgroundImage: `url(${banner})` };
    }
    return;
  }, [banner, currentLocation]);

  const handleSearch = useCallback(
    async formValues => {
      const job = formValues.job;
      const location = formValues.location;
      if (job !== '' && location !== '') {
        startLoading();
        window.location.href = `/search-craftsman?job=${job}&location=${location}#find-craftman`;
      }
    },
    [startLoading],
  );

  return (
    <div
      style={handleShowHeaderBackgroundImage()}
      className={cn('header', {
        headerBgProperties:
          currentLocation.pathname !== MY_ACCOUNT_ROUTE() && !currentLocation.pathname.includes('/craftsman'),
      })}
    >
      <Row
        className={cn('header-main', {
          showBlackBgColor:
            currentLocation.pathname === MY_ACCOUNT_ROUTE() || currentLocation.pathname.includes('/craftsman'),
        })}
      >
        <Col lg={5} md={24}>
          <Logo />
        </Col>
        <Col lg={19} md={24}>
          <NavigationBar items={items} />
        </Col>
      </Row>
      <NavigationDrawer items={items} />
      {currentLocation.pathname !== MY_ACCOUNT_ROUTE() && !currentLocation.pathname.includes('/craftsman') && (
        <div className="header-content">
          <Title level={1} className="header-main-title" weight={FontWeight.BOLD}>
            {title}
          </Title>
          <SearchHomeCraftsmanForm handleSubmit={handleSearch} />
        </div>
      )}
    </div>
  );
};

export default Header;
