import React, { useCallback, useEffect, useState } from 'react';
import { Col, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

import { Text, Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import Button from '~/components/buttons/Button/Button';
import Image from '~/components/Image/Image';
import phoneLock from '~/assets/icons/phone-lock.png';
import VerificationField from '~/components/fields/VerificationField/VerificationField';
import Timer from '~/components/Timer/Timer';
import Loader from '~/components/loaders/Loader/Loader';
import { LOGIN_ROUTE } from '~/routing/routes';
import { UsersService } from '~/services/UsersService';

import './VerificationCodeForm.scss';

const VerificationCodeForm: React.FC = ({}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [isCodeSent, setCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receiverPhone, setReceiverPhone] = useState(String(localStorage.getItem('userInstantEmail')));
  const RESEND_CODE_DELAY_IN_SECONDS = 60;
  const { push: redirect } = useHistory();

  const postVerificationCode = useCallback(async (phone: string, verificationCode: string) => {
    setLoading(true);
    try {
      const res = await UsersService.checkVerificationCode(phone, verificationCode);
      if (res?.success) {
        localStorage.removeItem('userInstantEmail');
        redirect(LOGIN_ROUTE());
      }
    } catch (error: any) {
      setLoading(false);
      message.error(error.response?.data?.statusText || t('global:generalResponse'), 4);
    }
  }, []);

  const resendCode = useCallback(async (phone: string) => {
    try {
      const res = await UsersService.sendVerificationCode(phone);
      if (res?.success) {
        setCodeSent(true);
      }
    } catch (error: any) {
      message.error(error.response?.data?.statusText || t('global:generalResponse'), 4);
    }
  }, []);

  const onChange = useCallback((value: string) => {
    setCode(value);
    if (value.length === 6) {
      postVerificationCode(receiverPhone, value);
    }
  }, []);

  useEffect(() => {
    const getPhone = localStorage.getItem('userInstantEmail');
    setReceiverPhone(String(getPhone));
  }, []);
  return (
    <div className="VerificationCodeForm">
      <Row justify="center">
        <Col lg={12} sm={24} xs={24}>
          <div className="VerificationCodeForm-wrapper-legend">
            <div className="VerificationCodeForm-legend">
              <Title className="VerificationCodeForm-title" weight={FontWeight.BOLD} level={2}>
                {t('register:createYourAccount')}
              </Title>
              <p className="VerificationCodeForm-subtitle">{t('register:openYourArtisanCustomer')}</p>
            </div>
          </div>
        </Col>
        <Col lg={12} sm={24} xs={24}>
          <div className="VerificationCodeForm-wrapper">
            <div className="VerificationCodeForm-verificationCode-legend">
              <Row justify="center">
                <Image image={phoneLock} width={40} height={45} />
              </Row>
              <Row className="VerificationCodeForm-verificationCode-wrapper" justify="center">
                <Text size="s">
                  {t('register:pleaseEnterTheDigitCode')}
                  <span className="verificationCode-emailAddress"> {receiverPhone}</span>
                </Text>
              </Row>
            </div>
            {loading ? (
              <Row className="mt-7" justify="center">
                <Loader />
              </Row>
            ) : (
              <div className="verificationField-wrapper">
                <VerificationField onChange={onChange} value={code} disabled={loading} />
                <Row justify="center" className="VerificationCodeForm-button-container">
                  {!isCodeSent ? (
                    <Button
                      onClick={() => resendCode(receiverPhone)}
                      className="VerificationCodeForm-resend-button"
                      type="button"
                      title={t('register:resendCode')}
                      size="s"
                      color="light"
                      icon={<SyncOutlined className="verification-icon" />}
                    />
                  ) : (
                    <Timer
                      secondsProp={RESEND_CODE_DELAY_IN_SECONDS}
                      component={seconds => (
                        <Text size="s" className="VerificationCodeForm-resend-message">{` ${t(
                          'register:youCanReceive',
                        )} ${seconds}s`}</Text>
                      )}
                      onEnd={() => setCodeSent(false)}
                    />
                  )}
                </Row>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VerificationCodeForm;
