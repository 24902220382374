import { Popover, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkedinOutlined, MessageOutlined, PhoneOutlined } from '@ant-design/icons';

import Image from '~/components/Image/Image';
import { Title } from '~/components/Typo/Typo';
import { FontWeight, TextVariants } from '~/theme/constants';

import './AuthorLayout.scss';

const AuthorLayout: React.FC = () => {
  const { t } = useTranslation();
  // TODO: Store all its values on the back-end side
  const fullName = 'Jude Ette';
  const job = t('home:seniorFullStackEngineer');
  const bio = t('home:authorBiography');
  const link = 'https://www.linkedin.com/in/jude-ette-74320315b/';
  const mailTo = 'judeette@outlook.com';
  const tel = '+2250789463461';
  const authorAvatar = 'https://res.cloudinary.com/tetratech/image/upload/v1632098627/personal/author_xnid9j.png';
  const authorInfos = useMemo(
    () => [
      {
        onClick: () => window.open(link, '_blank'),
        title: 'Linkedin',
        content: link,
        icon: <LinkedinOutlined className="authorLayout-icon" />,
      },
      {
        onClick: () => (window.location.href = `tel:${tel}`),
        title: 'Phone',
        content: tel,
        icon: <PhoneOutlined className="authorLayout-icon" />,
      },
      {
        onClick: () => (window.location.href = `mailto:${mailTo}`),
        title: 'Email',
        content: mailTo,
        icon: <MessageOutlined className="authorLayout-icon" />,
      },
    ],
    [],
  );

  return (
    <div className="author-layout">
      <Row justify="center">
        <Image image={authorAvatar} height={180} width={180} circle border="dark" />
      </Row>
      <div className="authorLayout-content">
        <Title className="authorLayout-fullName" weight={FontWeight.MEDIUM} level={1} variant={TextVariants.PRIMARY}>
          {fullName.toUpperCase()}
        </Title>
        <p className="authorLayout-job">{job}</p>
        <p className="authorLayout-bio">{bio}</p>
        <Row className="authorLayout-contact" justify="center">
          {authorInfos.map((info, idx) => (
            <div key={idx} className="authorLayout-icon-container" onClick={info.onClick}>
              <Popover title={info.title} content={info.content}>
                {info.icon}
              </Popover>
            </div>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default AuthorLayout;
