import cn from 'classnames';
import React, { HTMLProps } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import './Navbar.scss';

interface ItemProps extends HTMLProps<HTMLDivElement> {
  link: string;
  active?: boolean;
}

interface NavbarSubComponents {
  Item: React.FC<ItemProps>;
}

interface NavbarProps extends HTMLProps<HTMLDivElement> {}

const Navbar: React.FC<NavbarProps> & NavbarSubComponents = ({ children, className }) => (
  <div className={cn(className, 'navbar')}>{children}</div>
);

const Item: React.FC<ItemProps> = ({ children, link, active, className }) => (
  <Link className={cn(className, 'navbar-item', { active })} to={link}>
    <span>{children}</span>
  </Link>
);

Navbar.Item = Item;

export default Navbar;
