import { LoadingOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React, { createContext, ReactNode, useCallback, useMemo, useState } from 'react';

export const LoadingContext = createContext({
  loading: false,
  startLoading: () => {},
  stopLoading: () => {},
});

export function LoadingContextProvider(props: { children: ReactNode }) {
  const [loading, setLoading] = useState(false);

  const startLoading = useCallback(() => setLoading(true), []);
  const stopLoading = useCallback(() => setLoading(false), []);

  const value = useMemo(() => ({ loading, startLoading, stopLoading }), [loading, startLoading, stopLoading]);

  return (
    <LoadingContext.Provider value={value}>
      {loading && (
        <Space className="global-loader" align="center">
          <LoadingOutlined size={5} />
        </Space>
      )}
      {props.children}
    </LoadingContext.Provider>
  );
}
