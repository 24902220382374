/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Image as AntImage, Col, Divider, message, Row } from 'antd';
import { LocationOn, Phone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import Card, { CardProps } from '~/components/Card/Card';
import Image from '~/components/Image/Image';
import avatar from '~/assets/images/register/avatar.png';
import { Paragraph, Text, Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import TradeWrapper from '~/components/TradeWrapper/TradeWrapper';
import Button from '~/components/buttons/Button/Button';
import { Image as Images } from '~/entities/responses/uploadImagesResponse';
import MessageField from '~/components/fields/MessageField/MessageField';
import { SessionsService } from '~/services/SessionsService';
import { Comment } from '~/entities/user';
import { CraftsmenService } from '~/services/CraftsmenService';

import './CraftmanProfileCard.scss';

interface CraftmanProfileCardProps extends Omit<CardProps, 'size'> {
  firstName: string;
  lastName: string;
  job: string;
  biography: string;
  localisation: string;
  phone: string;
  picture: string;
  images: Images;
  comments: Comment[];
  id?: string;
}

const CraftmanProfileCard: React.FC<CraftmanProfileCardProps> = ({
  className,
  rounded = true,
  firstName,
  lastName,
  job,
  biography,
  localisation,
  phone,
  picture,
  images,
  id,
}) => {
  const { t } = useTranslation();
  const getPicture = useCallback((): string => {
    switch (picture) {
      case '':
        return avatar;
      case undefined:
        return avatar;
      case null:
        return avatar;
      default:
        return picture;
    }
  }, [avatar, picture]);

  const [imagesTab, setImagesTab] = useState<any[]>([]);
  const [comment, setComment] = useState<Comment[]>([]);

  const handleSetImages = useCallback(() => {
    const freshImagesArray: any[] = [];
    if (images && images.image1 && images.image1 !== '') {
      freshImagesArray.push(images.image1);
    }
    if (images && images.image2 && images.image2 !== '') {
      freshImagesArray.push(images.image2);
    }
    if (images && images.image3 && images.image3 !== '') {
      freshImagesArray.push(images.image3);
    }
    if (images && images.image4 && images.image4 !== '') {
      freshImagesArray.push(images.image4);
    }
    if (images && images.image5 && images.image5 !== '') {
      freshImagesArray.push(images.image5);
    }
    setImagesTab(freshImagesArray);
  }, [images]);

  const validate = Yup.object({
    comment: Yup.string().required('Commentaire requis'),
  });

  const handlePostComment = useCallback(async formValues => {
    const loadingKey = 'loadingKey';
    try {
      message.loading({
        content: 'Envoie du commentaire en cours',
        key: loadingKey,
      });
      const res = await CraftsmenService.postComment({ ...formValues, craftsman: id });
      if (res?.success) {
        message.destroy(loadingKey);
        getCraftsmanForSetComments();
        message.info('votre commentaire a bien été posté', 2);
      }
    } catch (error: any) {
      message.destroy(loadingKey);
      message.error('Impossible de poster votre commentaire', 3);
    }
  }, []);

  const getCraftsmanForSetComments = useCallback(async () => {
    try {
      const res = await CraftsmenService.getCraftsman(String(id));
      if (res?.email && res.comments) {
        setComment(res.comments);
      } else {
        setComment([]);
      }
    } catch (error: any) {
      setComment([]);
      console.error('get comments error', error);
    }
  }, []);

  useEffect(() => {
    getCraftsmanForSetComments();
    handleSetImages();
  }, []);
  return (
    <Card rounded={rounded} className={cn(className, 'craftmanProfileCard')}>
      <Row>
        <Col lg={12} sm={24} xs={24}>
          <div className="craftmanProfileCard-personalInfos-wrapper">
            <Row justify="center">
              <Image image={getPicture()} circle height={120} width={120} />
            </Row>
            <div className="craftmanProfileCard-fullName">
              <Text weight={FontWeight.LIGHT} className="craftmanProfileCard-lastName">
                {lastName + ' '}
              </Text>
              <Text weight={FontWeight.LIGHT} className="craftmanProfileCard-firstName">
                {' ' + firstName}
              </Text>
            </div>
            <div className="craftmanProfileCard-job-wrapper">
              <Text weight={FontWeight.LIGHT} className="craftmanProfileCard-job">
                {job}
              </Text>
            </div>
            <div className="craftmanProfileCard-divider"></div>
            <Paragraph className="craftmanProfileCard-biography">
              {biography !== null && biography !== '' ? biography : 'Aucun texte de présentation'}
            </Paragraph>
          </div>
        </Col>
        <Col lg={12} sm={24} xs={24}>
          <div className="craftmanProfileCard-professionalInfos-wrapper">
            <TradeWrapper className="craftmanProfileCard-tradeWrapper" trade={job} />
            <div className="craftmanProfileCard-localisation">
              <LocationOn fontSize="large" />
              <p className="craftmanProfileCard-localisationContent">Abidjan, {localisation}</p>
            </div>
            <Button
              onClick={() => (window.location.href = 'tel:' + phone)}
              icon={<Phone style={{ fontSize: 19, marginRight: 5 }} fontSize="inherit" />}
              title={t('profile:call')}
              circle
              color="success"
              size="s"
            />
            <Divider className="divider-craftsmanProfile" plain />
            <div className="craftsmanProfileCard-portfolio-section">
              <Title level={3} weight={FontWeight.NORMAL}>
                Portfolio
              </Title>
              <div>
                {imagesTab.length > 0 ? (
                  <Row>
                    <AntImage.PreviewGroup>
                      {imagesTab?.map(
                        (e, idx) =>
                          e !== null &&
                          e !== undefined && (
                            <Col className="craftsmanProfileCard-image" lg={6} sm={12} xs={12}>
                              <AntImage style={{ height: 120 }} width={'100%'} src={e} />
                            </Col>
                          ),
                      )}
                    </AntImage.PreviewGroup>
                  </Row>
                ) : (
                  <p className="text-center empty-text">Vide</p>
                )}
              </div>
            </div>
            <Divider className="divider-craftsmanProfile" plain />
            <div className="craftsmanProfileCard-comments">
              <Title level={3} weight={FontWeight.NORMAL}>
                Avis ({comment.length})
              </Title>
              <div className="craftsmanProfileCard-comments-wrapper">
                {comment.map((com, idx) => (
                  <Card key={idx} slightlyRounded className="comments">
                    <Row style={{ verticalAlign: 'middle' }}>
                      <div className="comments-avatar">{com.author.firstName[0]}</div>
                      <div className="comments-user">
                        <Text className="comments-fullName">{`${com.author.firstName} ${com.author.lastName}`}</Text>
                        <br />
                        <Text className="comments-date">{moment(new Date(com.createdAt)).fromNow()}</Text>
                      </div>
                    </Row>
                    <div className="comments-text-content">{com.comment}</div>
                  </Card>
                ))}
                <Divider className="mt-5 mb-5" plain />
                <Formik
                  initialValues={{ comment: '' }}
                  onSubmit={values => handlePostComment(values)}
                  validationSchema={validate}
                >
                  {formik => (
                    <Form>
                      <MessageField
                        height={130}
                        textAreaClassName="comments-text-field form-control"
                        name="comment"
                        label="Votre avis"
                        disabled={!SessionsService.isLoggedIn()}
                      />

                      <Button
                        disabled={!SessionsService.isLoggedIn()}
                        className="mt-4"
                        title="Envoyer"
                        type="submit"
                        color="warning"
                        size="s"
                        rounded
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default CraftmanProfileCard;
