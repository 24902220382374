import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';

import i18n from '~/i18n';

import { LoadingContextProvider } from './contexts/LoaderContext';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';
import './index.scss';

ReactDOM.render(
  <LoadingContextProvider>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </LoadingContextProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
