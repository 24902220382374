import React from 'react';
import { BellFilled } from '@ant-design/icons';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import { Text, Title } from '~/components/Typo/Typo';
import { FontWeight, TextVariants } from '~/theme/constants';

import './NotificationLayout.scss';

interface Notification {
  title: string;
  message: string;
  type?: string;
}

interface NotificationLayoutProps {
  notifications: Notification[];
}

const NotificationLayout: React.FC<NotificationLayoutProps> = ({ notifications }) => {
  const { t } = useTranslation();
  return (
    <div className="notificationLayout">
      <div className="notificationLayout-header">
        <Title level={3} weight={FontWeight.NORMAL} className="notificationLayout-header-title">
          {'Notifications'}
        </Title>
      </div>
      <div className="notificationLayout-content">
        <Divider />
        {notifications.length > 0 ? (
          notifications.map((notification, idx) => (
            <div key={idx} className="notificationLayout-wrapper">
              <BellFilled className="notify-icon" />
              <div className="notificationLayout-notify-body">
                <Title
                  className="notificationLayout-notify-title"
                  weight={FontWeight.MEDIUM}
                  level={4}
                  variant={TextVariants.PRIMARY}
                >
                  {notification.title}
                </Title>
                <Text variant={TextVariants.PRIMARY}> {notification.message}</Text>
              </div>
            </div>
          ))
        ) : (
          <p className="noNotification">{t('myAccount:noNotifications')}</p>
        )}
      </div>
    </div>
  );
};

export default NotificationLayout;
