import { Alert, Col, message, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TextLoop } from 'react-text-loop-next';

import { Title } from '~/components/Typo/Typo';
import { FontWeight, TextVariants } from '~/theme/constants';
import craftsmanIcon from '~/assets/icons/craftsman-icon.png';
import Image from '~/components/Image/Image';
import CraftmanProfileCard from '~/containers/CraftmanProfileCard/CraftmanProfileCard';
import { CraftsmenService } from '~/services/CraftsmenService';
import { Comment, User } from '~/entities/user';
import Loader from '~/components/loaders/Loader/Loader';
import { Image as Images } from '~/entities/responses/uploadImagesResponse';

import './CraftmenProfile.scss';

type id = Record<string, string | undefined>;

const CraftmenProfile: React.FC = () => {
  const { id } = useParams<id>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [craftsman, setCraftsman] = useState<User>();

  const getCraftsman = useCallback(async () => {
    setLoading(true);
    try {
      const res = await CraftsmenService.getCraftsman(String(id));
      if (res?.email) {
        setCraftsman(res);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      message.error(error.response?.data?.statusText || t('profile:craftsmanError'), 3);
    }
  }, []);

  useEffect(() => {
    getCraftsman();
  }, []);
  return (
    <div className="craftmenProfile">
      <div className="craftmenProfile-header">
        <Row>
          <Image image={craftsmanIcon} height={35} width={35} />
          <Title className="craftmenProfile-title" level={2} variant={TextVariants.PRIMARY} weight={FontWeight.BOLD}>
            {t('profile:craftsmanProfile')}
          </Title>
        </Row>
      </div>
      <div className="craftsmenProfile-content">
        {loading ? (
          <Loader />
        ) : (
          <>
            <CraftmanProfileCard
              firstName={craftsman?.firstName as string}
              lastName={craftsman?.lastName as string}
              job={craftsman?.job as string}
              localisation={craftsman?.town as string}
              biography={craftsman?.biography as string}
              phone={craftsman?.phone as string}
              picture={craftsman?.picture as string}
              images={craftsman?.images as Images}
              comments={craftsman?.comments as Comment[]}
              id={id}
            />
            <Row justify="center">
              <Col lg={12} sm={24} xs={24}>
                <Alert
                  className="mt-4"
                  banner
                  message={
                    <TextLoop mask>
                      <div className="alert-message-waring">
                        Ce profil n'as pas été publié par le site lesartisans.ci
                      </div>
                      <div className="alert-message-waring">
                        Lesartisans.ci ne peut donc garantir le service proposé
                      </div>
                      <div className="alert-message-waring">En cas de litige contacter le service client</div>
                    </TextLoop>
                  }
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default CraftmenProfile;
