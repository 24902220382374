import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Button from '~/components/buttons/Button/Button';
import AutoCompleteField from '~/components/fields/AutoCompleteField/AutoCompleteField';
import { ContactFormProps } from '~/components/forms/ContactForm/ContactForm';

import './SearchCraftmanForm.scss';

interface SearchCraftmanFormProps extends ContactFormProps {
  handleSubmit: (values: any) => void;
}

const SearchCraftmanForm: React.FC<SearchCraftmanFormProps> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const validate = Yup.object({
    job: Yup.string().required(),
    location: Yup.string().required(),
  });

  return (
    <Formik
      onSubmit={values => {
        handleSubmit(values);
      }}
      initialValues={{ job: '', location: '' }}
      validationSchema={validate}
      enableReinitialize
    >
      {formik => (
        <Form className="searchCraftmanForm">
          <Row>
            <Col lg={12} xs={24}>
              <AutoCompleteField
                onSelect={value => formik.setFieldValue('job', value)}
                options={[
                  { value: 'peintre' },
                  { value: 'couvreur' },
                  { value: 'plombier' },
                  { value: 'jardinier' },
                  { value: 'maçon' },
                  { value: 'carreleur' },
                  { value: 'tapissier' },
                  { value: 'chauffagiste' },
                  { value: 'taxidermiste' },
                  { value: 'esthéticienne' },
                  { value: 'tailleur' },
                  { value: 'ébéniste' },
                  { value: 'vitrailliste' },
                  { value: 'horloger' },
                  { value: 'électricien' },
                  { value: 'menuisier' },
                  { value: 'jardinier' },
                  { value: 'cuisine' },
                  { value: 'serrurier' },
                  { value: 'technicien froid et climatisation' },
                  { value: 'cordonnier' },
                ]}
                height={85}
                className="text-field-searchCraftmanForm"
                name="job"
                placeholder={t('findCraftman:searchCraftmanForm:whichJob')}
              />
            </Col>
            <Col lg={12} xs={24}>
              <AutoCompleteField
                onSelect={value => formik.setFieldValue('location', value)}
                options={[
                  { value: 'abobo', text: 'Abobo' },
                  { value: 'adjamé', text: 'Adjamé' },
                  { value: 'anyama', text: 'Anyama' },
                  { value: 'attécoubé', text: 'Attécoubé' },
                  { value: 'bingerville', text: 'Bingerville' },
                  { value: 'cocody', text: 'Cocody' },
                  { value: 'koumassi', text: 'Koumassi' },
                  { value: 'marcory', text: 'Marcory' },
                  { value: 'plateau', text: 'Plateau' },
                  { value: 'port bouët', text: 'Port bouët' },
                  { value: 'treichville', text: 'Treichville' },
                  { value: 'songon', text: 'Songon' },
                  { value: 'yopougon', text: 'Yopougon' },
                ]}
                height={85}
                className="text-field-searchCraftmanForm"
                name="location"
                placeholder={t('findCraftman:searchCraftmanForm:location')}
              />
            </Col>
          </Row>
          <Row className="mt-2" justify="center">
            <Button
              title={t('findCraftman:searchCraftmanForm:search')}
              rounded
              type="submit"
              color="warning"
              size="m"
            />
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default SearchCraftmanForm;
