import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Row } from 'antd';
import * as Yup from 'yup';

import { Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import { ContactFormProps } from '~/components/forms/ContactForm/ContactForm';
import TextField from '~/components/fields/TextField/TextField';
import MessageField from '~/components/fields/MessageField/MessageField';
import { User, UserCategory } from '~/entities/user';
import Button from '~/components/buttons/Button/Button';

import './AccountForm.scss';

interface AccountFormProps extends ContactFormProps {
  user: User;
}

const AccountForm: React.FC<AccountFormProps> = ({ handleSubmit, user }) => {
  const { t } = useTranslation();
  const validate = Yup.object({
    lastName: Yup.string().required(t('home:contact:contactForm:validationMessages:lastName')),
    firstName: Yup.string().required(t('home:contact:contactForm:validationMessages:firstName')),
    email: Yup.string()
      .email(t('home:contact:contactForm:validationMessages:email'))
      .required(t('home:contact:contactForm:validationMessages:emailRequired')),
  });

  return (
    <div className="accountForm">
      <div className="accountForm-header">
        <Title level={3} weight={FontWeight.NORMAL} className="accountForm-header-title">
          {t('myAccount:userInformation')}
        </Title>
      </div>
      <div className="accountForm-content">
        <Formik
          onSubmit={values => {
            handleSubmit(values);
          }}
          initialValues={{
            lastName: user?.lastName,
            firstName: user?.firstName,
            email: user?.email,
            phone: user?.phone,
            city: user?.city,
            town: user?.town,
            biography: user?.biography,
          }}
          validationSchema={validate}
          enableReinitialize
        >
          {formik => (
            <Form>
              <div className="accountForm-userInformations">
                <Row>
                  <Col lg={12} sm={24} xs={24}>
                    <TextField
                      height={50}
                      className="text-field-accountForm"
                      type="text"
                      label={t('register:lastName')}
                      placeholder={t('register:lastName')}
                      name="lastName"
                    />
                  </Col>
                  <Col lg={12} sm={24} xs={24}>
                    <TextField
                      height={50}
                      className="text-field-accountForm"
                      type="text"
                      label={t('register:firstName')}
                      placeholder={t('register:firstName')}
                      name="firstName"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} sm={24} xs={24}>
                    <TextField
                      height={50}
                      className="text-field-accountForm"
                      type="email"
                      label={'Email'}
                      placeholder={'Email'}
                      name="email"
                    />
                  </Col>
                  <Col lg={12} sm={24} xs={24}>
                    <TextField
                      height={50}
                      className="text-field-accountForm"
                      type="tel"
                      label={t('register:phone')}
                      placeholder={t('register:phone')}
                      name="phone"
                      readOnly
                    />
                  </Col>
                </Row>
              </div>

              {user?.userType === UserCategory.CRAFTSMAN && (
                <div className="accountForm-contact-informations">
                  <Divider plain />
                  <div className="accountForm-contact-informations-above">
                    <Title
                      level={3}
                      weight={FontWeight.NORMAL}
                      className="accountForm-contact-informations-above-title"
                    >
                      {t('myAccount:contactInformation')}
                    </Title>
                  </div>
                  <div className="accountForm-contact-informations-below">
                    <Row>
                      <Col lg={12} sm={24} xs={24}>
                        <TextField
                          height={50}
                          className="text-field-accountForm"
                          type="text"
                          label={t('myAccount:city')}
                          placeholder={t('myAccount:city')}
                          name="city"
                          readOnly
                        />
                      </Col>
                      <Col lg={12} sm={24} xs={24}>
                        <TextField
                          height={50}
                          className="text-field-accountForm"
                          type="text"
                          label={t('myAccount:town')}
                          placeholder={t('myAccount:town')}
                          name="town"
                        />
                      </Col>
                    </Row>
                    <MessageField
                      height={100}
                      className="text-field-accountForm"
                      label={t('myAccount:description')}
                      placeholder={t('myAccount:description')}
                      name="biography"
                    />
                  </div>
                </div>
              )}

              <Row justify="center">
                <Button
                  className="accountForm-update-button"
                  title={t('myAccount:update')}
                  rounded
                  color="warning"
                  type="submit"
                />
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AccountForm;
