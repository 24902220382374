import React from 'react';
import { Form, Formik } from 'formik';
import { Col, Row } from 'antd';
import * as Yup from 'yup';

import { Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import { ContactFormProps } from '~/components/forms/ContactForm/ContactForm';
import UploadImageField from '~/components/fields/UploadImageField/UploadImageField';
import Button from '~/components/buttons/Button/Button';
import Loader from '~/components/loaders/Loader/Loader';

import './UploadPicturesForm.scss';

interface UploadPicturesFormProps extends ContactFormProps {
  image1?: string;
  image2?: string;
  image3?: string;
  image4?: string;
  image5?: string;
  loader?: boolean;
}

const UploadPicturesForm: React.FC<UploadPicturesFormProps> = ({
  handleSubmit,
  image1,
  image2,
  image3,
  image4,
  image5,
  loader,
}) => {
  const validate = Yup.object({});
  return (
    <div className="uploadPicturesForm">
      <div className="uploadPicturesForm-header mb-4">
        <Title level={4} weight={FontWeight.NORMAL} className="uploadPicturesForm-header-title">
          {'Ajouter des photos de présentation'}
        </Title>
      </div>
      {loader ? (
        <Row justify="center">
          <Loader />
        </Row>
      ) : (
        <div className="uploadPicturesForm-content">
          <Formik
            onSubmit={values => {
              handleSubmit(values);
            }}
            validationSchema={validate}
            initialValues={{ image1, image2, image3, image4, image5 }}
            enableReinitialize
          >
            {formik => (
              <Form>
                <Row justify="center">
                  <Col style={{ padding: 5 }} lg={5} md={6} sm={12} xs={12} className="mb-2">
                    <UploadImageField name="image1" formProps={formik} defaultImage={image1} />
                  </Col>
                  <Col style={{ padding: 5 }} lg={5} md={6} sm={12} xs={12} className="mb-2">
                    <UploadImageField name="image2" formProps={formik} defaultImage={image2} />
                  </Col>
                  <Col style={{ padding: 5 }} lg={5} md={6} sm={12} xs={12} className="mb-2">
                    <UploadImageField name="image3" formProps={formik} defaultImage={image3} />
                  </Col>
                  <Col style={{ padding: 5 }} lg={5} md={6} sm={12} xs={12} className="mb-2">
                    <UploadImageField name="image4" formProps={formik} defaultImage={image4} />
                  </Col>
                  <Col style={{ padding: 5 }} lg={5} md={6} sm={12} xs={12} className="mb-2">
                    <UploadImageField name="image5" formProps={formik} defaultImage={image5} />
                  </Col>
                </Row>
                <Row justify="center" className="mt-1">
                  <Button title="Enregistrer" type="submit" color="warning" rounded size="s" />
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default UploadPicturesForm;
