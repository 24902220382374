import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { SessionsService } from '~/services/SessionsService';

const PrivateRoute: React.FC<Record<string, any>> = ({ component: Component, render, ...rest }) => (
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /login page
  <Route
    {...rest}
    render={props => (SessionsService.isLoggedIn() ? render?.() || <Component {...props} /> : <Redirect to="/login" />)}
  />
);

export default PrivateRoute;
