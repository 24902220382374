export const HOME_ROUTE = () => '/';
export const LOGIN_ROUTE = () => '/login';

export const REGISTER_ROUTE = () => '/register/:id';
export const MY_ACCOUNT_ROUTE = () => '/account';

export const FORGOT_PASSWORD_ROUTE = () => '/forgot-password';

export const OUR_SERVICES_ROUTE = () => '/#ourServices';

export const FIND_CRAFTMAN_ROUTE = () => '/find-craftman';

export const CONTACT_ROUTE = () => '/#contact';

export const CRAFTMEN_DIRECTORY = () => '/craftmen-directory';

export const CRAFTMEN_PROFILE = () => '/craftsman/:id';

export const SEARCH_CRAFTSMAN = () => '/search-craftsman';
