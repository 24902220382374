import { GetCraftsmenResponse } from '~/entities/responses/getCraftsmenResponse';
import { User } from '~/entities/user';
import { APIService } from '~/services/ApiService';
import GeneralResponse from '~/entities/responses/generalResponse';

interface PostCommentBody {
  comment: string;
  craftsman: string;
}

export const CraftsmenService = {
  async getLastRegisteredCraftsmen(limit: number): Promise<GetCraftsmenResponse> {
    const res = await APIService.call(
      'GET',
      `/users/craftsmen/getSome/${limit}`,
      undefined,
      undefined,
      {
        Authorization: `Bearer ${process.env.REACT_APP_MASTER_KEY}`,
      },
      undefined,
      true,
    );
    return res.data;
  },
  async searchCraftsmen(town: string, job: string): Promise<GetCraftsmenResponse> {
    const res = await APIService.call(
      'GET',
      '/users/craftsmen/search',
      undefined,
      { town, job },
      {
        Authorization: `Bearer ${process.env.REACT_APP_MASTER_KEY}`,
      },
      undefined,
      true,
    );
    return res.data;
  },
  async getCraftsman(id: string): Promise<Maybe<User>> {
    const res = await APIService.call(
      'GET',
      `/users/craftsmen/${id}/get`,
      undefined,
      undefined,
      {
        Authorization: `Bearer ${process.env.REACT_APP_MASTER_KEY}`,
      },
      undefined,
      true,
    );
    return res.data;
  },
  async postComment(body: PostCommentBody): Promise<Maybe<GeneralResponse>> {
    const res = await APIService.call('POST', '/users/comment/post', body);
    return res.data;
  },
};
