import React, { useCallback } from 'react';
import cn from 'classnames';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

import { FontWeight, TextVariants } from '~/theme/constants';
import { Title } from '~/components/Typo/Typo';
import Card, { CardProps } from '~/components/Card/Card';
import { CardSizes } from '~/containers/CraftsmenCard/CraftsmenCard';
import Image from '~/components/Image/Image';
import TradeWrapper from '~/components/TradeWrapper/TradeWrapper';
import Button from '~/components/buttons/Button/Button';

import './CraftmenDirectoryCard.scss';

interface CraftmenDirectoryCardProps extends Omit<CardProps, 'size'> {
  id?: string;
  fullName: string;
  avatar: string;
  trades: string[];
  localisation: string;
  size?: CardSizes;
  biography: string;
}

const CraftmenDirectoryCard: React.FC<CraftmenDirectoryCardProps> = ({
  id,
  fullName,
  avatar,
  trades,
  localisation,
  rounded = true,
  className,
  biography,
}) => {
  const { t } = useTranslation();
  const { push: redirect } = useHistory();

  const imagesMock = Array.from(Array(50).keys()).map(i => `https://picsum.photos/1000?random=${i}`);

  const handleGetPicture = useCallback((picture: string): string => {
    switch (picture) {
      case '':
        return imagesMock[0];
      case null:
        return imagesMock[0];
      case undefined:
        return imagesMock[0];
      default:
        return avatar;
    }
  }, []);
  return (
    <Card style={{ background: 'white' }} rounded={rounded} className={cn(className, 'craftmenDirectoryCard')}>
      <Card.Head className="craftmenDirectoryCard-head">
        <Row>
          <Col lg={12} sm={24} xs={24}>
            <Title weight={FontWeight.BOLD} variant={TextVariants.PRIMARY} className="craftmenDirectoryCard-title">
              {fullName}
            </Title>
          </Col>
          <Col lg={12} sm={24} xs={24} className="tar craftmenDirectoryCard-avatar-container">
            <Image
              className="craftmenDirectoryCard-avatar"
              image={handleGetPicture(avatar)}
              circle
              height={125}
              width={125}
              border="white"
            />
          </Col>
        </Row>
      </Card.Head>
      <div className="craftmenDirectoryCard-content">
        <Title level={5} variant={TextVariants.PRIMARY} weight={FontWeight.SEMIBOLD}>
          {t('craftmenDirectory:trades')}
        </Title>
        <div className="craftmenDirectoryCard-trades">
          {trades.map((trade, idx) => (
            <TradeWrapper key={idx} trade={trade} />
          ))}
        </div>
        <p className="craftmenDirectoryCard-localisation">{localisation}</p>
        <p className="craftmenDirectoryCard-biography">{biography}</p>
        <p className="craftmenDirectoryCard-intervention">{t('craftmenDirectory:intervention')}</p>
        <p className="craftmenDirectoryCard-interventions">{trades.map(trade => trade)}</p>
      </div>
      <Card.Foot className="craftmenDirectoryCard-foot">
        <Row justify="center">
          <Button
            icon={<PlusCircleOutlined className="button-icon" />}
            title={t('craftmenDirectory:viewProfile')}
            color="light"
            size="m"
            onClick={() => redirect(`craftsman/${id}`)}
          />
        </Row>
      </Card.Foot>
    </Card>
  );
};

export default CraftmenDirectoryCard;
