import React, { HTMLProps } from 'react';
import cn from 'classnames';

import { IconType } from '~/components/buttons/Button/Button';

import './ContactLayout.scss';

interface ContactLayoutProps extends HTMLProps<HTMLDivElement> {
  icon: IconType;
  title: string;
  value: string;
}

const ContactLayout: React.FC<ContactLayoutProps> = ({ className, icon, title, value, ...restParams }) => (
  <div {...restParams} className={cn(className, 'contact-layout')}>
    <div className="contact-icon">{icon}</div>
    <div className="contact-info">
      <p className="contact-info-title">{title}</p>
      <p className="contact-info-value">{value}</p>
    </div>
  </div>
);

export default ContactLayout;
