import React from 'react';
import { Link } from 'react-router-dom';

import logo from '~/assets/logos/artisan-logo.png';
import { HOME_ROUTE } from '~/routing/routes';

import './Logo.scss';

const Logo: React.FC = () => (
  <div className="logo">
    <Link to={HOME_ROUTE()}>
      <img src={logo} alt="logo" className="logo-img" />
    </Link>
  </div>
);

export default Logo;
