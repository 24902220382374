import { message, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import Logo from '~/containers/Logo/Logo';
import RegisterCustomerForm from '~/components/forms/RegisterCustomerForm/RegisterCustomerForm';
import RegisterCraftsmanForm from '~/components/forms/RegisterCraftsmanForm/RegisterCraftsmanForm';
import VerificationCodeForm from '~/components/forms/VerificationCodeForm/VerificationCodeForm';
import { UsersService } from '~/services/UsersService';
import { HOME_ROUTE } from '~/routing/routes';

import './Register.scss';

type id = Record<string, string | undefined>;

const Register: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  const { id } = useParams<id>();
  const { t } = useTranslation();
  const { push: redirect } = useHistory();

  const handleRegisterUser = useCallback(async formValues => {
    localStorage.setItem('userInstantEmail', '+225' + formValues.phone);
    const loadingMessageKey = 'loadingMessage';
    message.loading({
      key: loadingMessageKey,
      content: t('global:formSubmissionLoading'),
    });
    const newFormValues = {
      ...formValues,
      email: formValues?.email?.toLowerCase(),
      phone: '+225' + formValues.phone,
    };
    try {
      await UsersService.registration(newFormValues);
      message.destroy(loadingMessageKey);
      setSelectedItem(2);
    } catch (error: any) {
      message.destroy(loadingMessageKey);
      message.error(error.response?.data?.statusText || t('global:generalResponse'), 4);
    }
  }, []);

  const handleChangeRegisterForm = useCallback((index: number) => {
    if (!registerForms?.length) return;
    setSelectedItem(index);
  }, []);

  const registerForms = useMemo(
    () => [
      {
        form: (
          <RegisterCustomerForm handleSubmit={handleRegisterUser} handleChangeRegisterForm={handleChangeRegisterForm} />
        ),
      },
      {
        form: (
          <RegisterCraftsmanForm
            handleSubmit={handleRegisterUser}
            handleChangeRegisterForm={handleChangeRegisterForm}
          />
        ),
      },
      {
        form: <VerificationCodeForm />,
      },
    ],
    [],
  );

  const handleSelectDefaultForm = useCallback(() => {
    const parseId = Number(id);

    if (parseId > 1) {
      redirect(HOME_ROUTE());
    }
    if (isNaN(parseId)) {
      redirect(HOME_ROUTE());
    }
    setSelectedItem(parseId);
  }, [id]);

  useEffect(() => {
    handleSelectDefaultForm();
  }, [registerForms]);

  return (
    <div className={cn('register', { 'register-fixed': selectedItem === 2 })}>
      <div className="register-header">
        <Row justify="center">
          <Logo />
        </Row>
      </div>
      <div className="register-content">{registerForms[selectedItem].form}</div>
    </div>
  );
};

export default Register;
