import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, message, Row } from 'antd';
import { Email, LocationOn, Phone } from '@mui/icons-material';
import { useHistory } from 'react-router';

import { Text, Title } from '~/components/Typo/Typo';
import { FontWeight, TextVariants } from '~/theme/constants';
import floorTilePicture from '~/assets/icons/carrelage-sol.png';
import kitchenPicture from '~/assets/icons/electroménager.png';
import electricityPicture from '~/assets/icons/electricité.png';
import coldPicture from '~/assets/icons/froid.png';
import masonryPicture from '~/assets/icons/maconnerie.png';
import carpentryPicture from '~/assets/icons/menuserie.png';
import plomberie from '~/assets/icons/plomberie.png';
import paintPicture from '~/assets/icons/peinture.png';
import serrurerie from '~/assets/icons/serrurerie.png';
import mobileAppBannerPicture from '~/assets/images/home/mobile-app-banner.png';
import ServicesCard from '~/containers/ServicesCard/ServicesCard';
import MobileAppBanner from '~/containers/MobileAppBanner/MobileAppBanner';
import CraftsmenCard from '~/containers/CraftsmenCard/CraftsmenCard';
import ItemsSlider from '~/components/ItemsSlider/ItemsSlider';
import ContactLayout from '~/components/ContactLayout/ContactLayout';
import ContactForm from '~/components/forms/ContactForm/ContactForm';
import firstStep from '~/assets/images/home/first-step.png';
import secondStep from '~/assets/images/home/second-step.png';
import thirdStep from '~/assets/images/home/third-step.png';
import fourthStep from '~/assets/images/home/fourth-step.png';
import Image from '~/components/Image/Image';
import { UsersService } from '~/services/UsersService';
import { CraftsmenService } from '~/services/CraftsmenService';
import { User } from '~/entities/user';
import Loader from '~/components/loaders/Loader/Loader';
import Button from '~/components/buttons/Button/Button';
import { CRAFTMEN_DIRECTORY } from '~/routing/routes';

import './Home.scss';

const Home: React.FC = () => {
  const { t } = useTranslation();

  const [items, setItems] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const { push: redirect } = useHistory();

  const servicesCategories = useMemo(
    () => [
      {
        title: t('home:servicesCategories:electricity'),
        image: electricityPicture,
      },
      {
        title: t('home:servicesCategories:masonry'),
        image: masonryPicture,
      },
      {
        title: t('home:servicesCategories:carpentry'),
        image: carpentryPicture,
      },
      {
        title: t('home:servicesCategories:paint'),
        image: paintPicture,
      },
      {
        title: t('home:servicesCategories:gardening'),
        image: floorTilePicture,
      },
      {
        title: t('home:servicesCategories:floorTile'),
        image: floorTilePicture,
      },
      {
        title: t('home:servicesCategories:kitchen'),
        image: kitchenPicture,
      },
      {
        title: t('home:servicesCategories:locksmith'),
        image: serrurerie,
      },
      {
        title: t('home:servicesCategories:cold'),
        image: coldPicture,
      },
      {
        title: 'Plomberie',
        image: plomberie,
      },
    ],
    [
      electricityPicture,
      masonryPicture,
      carpentryPicture,
      paintPicture,
      floorTilePicture,
      kitchenPicture,
      coldPicture,
      t,
    ],
  );

  const contactInfos = useMemo(
    () => [
      { icon: <Phone fontSize="inherit" />, title: t('home:contact:callUs'), value: '+2250707732264' },
      { icon: <Email fontSize="inherit" />, title: 'E-mail', value: 'contact@lesartisans.ci' },
      { icon: <LocationOn fontSize="inherit" />, title: t('home:contact:address'), value: '10 BP 03 Abidjan 10' },
    ],
    [t],
  );

  const itemsUpdated = items.map((item, idx) => (
    <CraftsmenCard key={idx} image={item.picture} fullname={`${item.firstName} ${item.lastName}`} job={item.job} />
  ));

  const handleSubmitContactForm = useCallback(
    async formValues => {
      const loadingMessageKey = 'loadingMessage';
      message.loading({
        key: loadingMessageKey,
        content: t('global:formSubmissionLoading'),
      });
      try {
        const res = await UsersService.contact(formValues);
        if (res.success) {
          message.destroy(loadingMessageKey);
          message.success(t('home:contact:successMessage'));
        }
      } catch (error: any) {
        message.destroy(loadingMessageKey);
        message.error(error.response?.data?.statusText || t('global:generalResponse'), 4);
      }
    },
    [message],
  );

  const getCraftsmen = useCallback(async () => {
    try {
      const res = await CraftsmenService.getLastRegisteredCraftsmen(10);
      if (res.success) {
        setLoading(false);
        setItems(res.craftsmen);
      }
    } catch (error: any) {
      setLoading(false);
      console.error(error);
    }
  }, [setItems, setLoading]);

  useEffect(() => {
    getCraftsmen();
  }, []);

  return (
    <div className="home">
      <div className="how-it-works">
        <div className="how-it-works-header">
          <Title className="how-it-works-title" weight={FontWeight.BOLD}>
            {t('home:homeItWorks')}
          </Title>
        </div>
        <div className="how-it-works-content">
          <Row className="how-it-work-wrapper" justify="center">
            <Col className="tac how-it-work-inlineBlock" lg={12} sm={12}>
              <Image className="how-it-work-image-desc" image={firstStep} width={'75%'} height={'75%'} />
            </Col>
            <Col className="how-it-work-inlineBlock" lg={12} sm={12}>
              <Title className="how-it-work-desc-title" weight={FontWeight.BOLD} variant={TextVariants.PRIMARY}>
                {t('home:detectProblem')}
              </Title>
              <div className="how-it-work-desc-wrapper">
                <Text className="how-it-work-desc-content">{t('home:goToSearchBar')}</Text>
              </div>
            </Col>
            <br /> <br /> <br />
            <Col className="how-it-work-inlineBlock" lg={12} sm={12}>
              <Title className="how-it-work-desc-title" weight={FontWeight.BOLD} variant={TextVariants.PRIMARY}>
                {t('home:indicatePlace')}
              </Title>
              <div className="how-it-work-desc-wrapper">
                <Text className="how-it-work-desc-content">{t('home:indicateInSearchBar')}</Text>
              </div>
            </Col>
            <Col className="tac how-it-work-inlineBlock" lg={12} sm={12}>
              <Image className="how-it-work-image-desc" image={secondStep} width={'75%'} height={'75%'} />
            </Col>
            <br /> <br /> <br />
            <Col className="tac how-it-work-inlineBlock" lg={12} sm={12}>
              <Image className="how-it-work-image-desc" image={thirdStep} width={'75%'} height={'75%'} />
            </Col>
            <Col className="how-it-work-inlineBlock" lg={12} sm={12}>
              <Title className="how-it-work-desc-title" weight={FontWeight.BOLD} variant={TextVariants.PRIMARY}>
                {t('home:selectACraftman')}
              </Title>
              <div className="how-it-work-desc-wrapper">
                <Text className="how-it-work-desc-content">{t('home:chooseAndVisitTheProfile')}</Text>
              </div>
            </Col>
            <br /> <br /> <br />
            <Col className="how-it-work-inlineBlock" lg={12} sm={12}>
              <Title className="how-it-work-desc-title" weight={FontWeight.BOLD} variant={TextVariants.PRIMARY}>
                {t('home:getQuote')}
              </Title>
              <div className="how-it-work-desc-wrapper">
                <Text className="how-it-work-desc-content">{t('home:finallyAskForQuote')}</Text>
              </div>
            </Col>
            <Col className="tac how-it-work-inlineBlock" lg={12} sm={12}>
              <Image className="how-it-work-image-desc" image={fourthStep} width={'75%'} height={'75%'} />
            </Col>
          </Row>
        </div>
      </div>
      <div id="ourServices" className="our-services">
        <div className="our-services-header">
          <Title className="our-services-title" weight={FontWeight.BOLD}>
            {t('home:ourServices')}
          </Title>
        </div>

        <div className="our-services-content">
          <Row>
            {servicesCategories.map((service, idx) => (
              <Col lg={8} sm={12} xs={24}>
                <ServicesCard className="services-card" key={idx} title={service.title} image={service.image} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <div className="download-app-now">
        <MobileAppBanner image={mobileAppBannerPicture} />
      </div>

      <div className="our-craftsmen">
        <div className="our-craftsmen-header">
          <Title className="our-craftsmen-title" weight={FontWeight.BOLD}>
            {t('home:ourCraftsmen')}
          </Title>
        </div>

        <div className="our-craftsmen-content">
          {loading ? (
            <Row justify="center">
              <Loader />
            </Row>
          ) : (
            <>
              <ItemsSlider items={itemsUpdated} />
              <Row justify="center" className="mt-6">
                <Button
                  onClick={() => redirect(CRAFTMEN_DIRECTORY())}
                  title={t('home:seeMore')}
                  color="warning"
                  size="s"
                  rounded
                />
              </Row>
            </>
          )}
          <br />
        </div>
      </div>
      <div id="contact" className="contact">
        <div className="contact-header">
          <Title className="contact-title" weight={FontWeight.BOLD}>
            {t('home:contactUs')}
          </Title>
        </div>
        <div className="contact-content">
          <Row>
            <Col lg={7} xs={24}>
              {contactInfos.map((contact, idx) => (
                <ContactLayout
                  className="home-contact-layout"
                  key={idx}
                  icon={contact.icon}
                  title={contact.title}
                  value={contact.value}
                />
              ))}
            </Col>
            <Col lg={17} xs={24}>
              <ContactForm handleSubmit={handleSubmitContactForm} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Home;
