import React, { HTMLProps } from 'react';
import cn from 'classnames';

import { Title } from '~/components/Typo/Typo';
import { FontWeight, TextVariants } from '~/theme/constants';

import './TradeWrapper.scss';

interface TradeWrapperProps extends HTMLProps<HTMLDivElement> {
  trade: string;
}

const TradeWrapper: React.FC<TradeWrapperProps> = ({ trade, className, ...restParams }) => (
  <div {...restParams} className={cn('tradeWrapper')}>
    <Title
      className={cn(className, 'tradeWrapper-title')}
      level={5}
      variant={TextVariants.PRIMARY}
      weight={FontWeight.LIGHT}
    >
      {trade}
    </Title>
  </div>
);

export default TradeWrapper;
