import React from 'react';

import Navbar from '~/components/Navbar/Navbar';

import './NavigationBar.scss';

interface Item {
  label?: string;
  link: string;
  active?: boolean;
}

export interface NavigationBarProps {
  items: Item[];
}

const NavigationBar: React.FC<NavigationBarProps> = ({ items }) => (
  <Navbar>
    {items.map(({ label, active, link }) => (
      <Navbar.Item key={link} link={link} active={active}>
        {label}
      </Navbar.Item>
    ))}
  </Navbar>
);

export default NavigationBar;
