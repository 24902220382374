import GeneralResponse from '~/entities/responses/generalResponse';
import { RegistrationResponse } from '~/entities/responses/registrationResponse';
import { User } from '~/entities/user';
import { APIService } from '~/services/ApiService';

interface UpdateUserInfosBody {
  firstName?: string;
  lastName?: string;
  email?: string;
  town?: string;
  picture?: string;
  biography?: string;
}

interface CreateUserBody {
  firstName: string;
  lastName: string;
  email?: string;
  phone: string;
  password: string;
  picture?: string;
  job?: string;
  town?: string;
  userType: string;
  biography?: string;
}

interface ContactBody {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

export const UsersService = {
  async getUser(): Promise<Maybe<User>> {
    const res = await APIService.call('GET', '/users/me');
    return res.data;
  },
  async registration(body: CreateUserBody): Promise<Maybe<RegistrationResponse>> {
    const res = await APIService.call('POST', '/users/registration', body);
    return res.data;
  },
  async updatePassword(oldPassword: string, newPassword: string): Promise<GeneralResponse> {
    const res = await APIService.call('PUT', '/users/update-password', { oldPassword, newPassword });
    return res.data;
  },
  async updateUserInfos(body: UpdateUserInfosBody): Promise<Maybe<User>> {
    const res = await APIService.call('PUT', '/users/update-me', body);
    return res.data;
  },
  async sendVerificationCode(phone: string): Promise<Maybe<GeneralResponse>> {
    const res = await APIService.call('POST', '/users/send-verification-code', { phone });
    return res.data;
  },
  async checkVerificationCode(phone: string, code: string): Promise<Maybe<GeneralResponse>> {
    const res = await APIService.call('POST', '/users/check-verification-code', { phone, code });
    return res.data;
  },
  async updateProfilePicture(picture: string): Promise<GeneralResponse> {
    const res = await APIService.call('PATCH', '/users/update-profile-picture', { picture });
    return res.data;
  },
  async contact(body: ContactBody): Promise<GeneralResponse> {
    const res = await APIService.call('POST', '/users/contact', body);
    return res.data;
  },
};
