import React, { HTMLProps } from 'react';
import { ErrorMessage, useField } from 'formik';
import cn from 'classnames';

import './TextField.scss';

export interface TextFieldProps extends Omit<HTMLProps<HTMLInputElement>, 'label' | 'name'> {
  label?: string;
  name: string;
}

const TextField: React.FC<TextFieldProps> = ({
  placeholder,
  disabled,
  label,
  type,
  className,
  name,
  height,
  readOnly,
  ...restParams
}) => {
  const [field, meta] = useField(name);

  return (
    <div className={cn(className, 'text-field')}>
      {label && (
        <label htmlFor={field.name} className="text-field-label">
          {label}
        </label>
      )}
      <input
        className={cn({ 'form-control': meta.touched && meta.error }, { 'is-invalid': meta.touched && meta.error })}
        {...restParams}
        {...field}
        style={{ height }}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
      />
      <ErrorMessage name={field.name} component="div" className="text-danger" />
    </div>
  );
};
export default TextField;
