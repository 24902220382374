import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';

import Navbar from '~/components/Navbar/Navbar';
import { NavigationBarProps } from '~/containers/Header/NavigationBar/NavigationBar';

import './FooterNav.scss';

interface FooterNavProps extends NavigationBarProps {}

const FooterNav: React.FC<FooterNavProps> = ({ items }) => (
  <div className="footerNav">
    <Navbar className="footerNav-navbar">
      {items.map(({ label, active, link }, idx) => (
        <Navbar.Item className="footerNav-navbar-item" key={idx} link={link} active={active}>
          <CaretRightOutlined className="footerNav-icon" /> {label}
        </Navbar.Item>
      ))}
    </Navbar>
  </div>
);

export default FooterNav;
