import React, { useCallback } from 'react';
import cn from 'classnames';

import Card, { CardProps } from '~/components/Card/Card';
import { Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';

import './CraftsmenCard.scss';

export type CardSizes = 's' | 'l' | 'xl';
interface CraftsmenCardProps extends Omit<CardProps, 'size'> {
  image?: string;
  fullname: string;
  job?: string;
  size?: CardSizes;
}

const CraftsmenCard: React.FC<CraftsmenCardProps> = ({
  image,
  fullname,
  job,
  className,
  slightlyRounded = true,
  size = 'l',
}) => {
  const imagesMock = Array.from(Array(50).keys()).map(id => `https://picsum.photos/1000?random=${id}`);

  const handleGetPicture = useCallback((picture: string): string => {
    switch (picture) {
      case '':
        return imagesMock[0];
      case null:
        return imagesMock[0];
      case undefined:
        return imagesMock[0];
      default:
        return image as string;
    }
  }, []);
  return (
    <Card
      style={{ backgroundImage: `url(${handleGetPicture(image as string)})` }}
      slightlyRounded={slightlyRounded}
      className={cn(className, 'craftsmenCard', size && `craftsmenCard-${size}`)}
    >
      <div className="craftsmenCard-below">
        <Title level={3} className="craftsmenCard-fullname" weight={FontWeight.BOLD}>
          {fullname}
        </Title>
        <Title level={4} className="craftsmenCard-job" weight={FontWeight.LIGHT}>
          {job}
        </Title>
      </div>
    </Card>
  );
};

export default CraftsmenCard;
