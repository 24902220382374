import { Col, message, Row } from 'antd';
import { BellOutlined, LockOutlined, PictureOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Title } from '~/components/Typo/Typo';
import { FontWeight, TextVariants } from '~/theme/constants';
import AccountForm from '~/components/forms/AccountForm/AccountForm';
import UpdatePasswordForm from '~/components/forms/UpdatePasswordForm/UpdatePasswordForm';
import NotificationLayout from '~/containers/NotificationLayout/NotificationLayout';
import Sidebar from '~/components/Sidebar/Sidebar';
import { UsersService } from '~/services/UsersService';
import { NotificationsService } from '~/services/NotificationsService';
import UploadPicturesForm from '~/components/forms/UploadPicturesForm/UploadPicturesForm';
import { UploadImageService } from '~/services/UploadImageService';
import { User } from '~/entities/user';

import './MyAccount.scss';

const MyAccount: React.FC = () => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(2);
  const [notifications, setNotifications] = useState<any>([]);
  const [countNotifications, setCountNotifications] = useState(0);
  const [picturesLoading, setPicturesLoading] = useState(false);
  const [user, setUser] = useState<Maybe<User>>();
  const [loader, setLoader] = useState(false);

  const getNotifications = useCallback(async () => {
    try {
      const res = await NotificationsService.getNotifications();
      if (res.success) {
        setNotifications(res.notifications);
        setCountNotifications(res.count);
      }
    } catch (error: any) {
      message.error('Impossible de récupérer les notifications pour le moment, Prière réessayer plus tard', 3);
      console.error(error);
    }
  }, []);

  const handleUpdateUserInfos = useCallback(async formValues => {
    const loadingMessageKey = 'loadingMessage';
    message.loading({
      key: loadingMessageKey,
      content: t('global:formSubmissionLoading'),
    });
    try {
      const res = await UsersService.updateUserInfos(formValues);
      if (res?.id) {
        message.destroy(loadingMessageKey);
        message.success(t('myAccount:YourInformationUpdated'), 4);
      }
    } catch (error: any) {
      message.destroy(loadingMessageKey);
      message.error(error.response?.data?.statusText || t('global:generalResponse'), 4);
    }
  }, []);

  const handleUpdatePassword = useCallback(async formValues => {
    const loadingMessageKey = 'loadingMessage';
    message.loading({
      key: loadingMessageKey,
      content: t('global:formSubmissionLoading'),
    });
    try {
      const res = await UsersService.updatePassword(formValues.oldPassword, formValues.newPassword);
      if (res?.success) {
        message.destroy(loadingMessageKey);
        message.success(t('myAccount:passwordUpdated'), 4);
        setSelectedItem(0);
      }
    } catch (error: any) {
      message.destroy(loadingMessageKey);
      message.error(error.response?.data?.statusText || t('global:generalResponse'), 4);
    }
  }, []);

  const updloadImage1 = useCallback(async image1 => {
    if (image1 && image1 !== '') {
      const resImg1 = await UploadImageService.uploadImage(image1);
      return resImg1.secure_url;
    }
    return '';
  }, []);
  const updloadImage2 = useCallback(async image2 => {
    if (image2 && image2 !== '') {
      const resImg2 = await UploadImageService.uploadImage(image2);
      return resImg2.secure_url;
    }
    return '';
  }, []);
  const updloadImage3 = useCallback(async image3 => {
    if (image3 && image3 !== '') {
      const resImg3 = await UploadImageService.uploadImage(image3);
      return resImg3.secure_url;
    }
    return '';
  }, []);
  const updloadImage4 = useCallback(async image4 => {
    if (image4 && image4 !== '') {
      const resImg4 = await UploadImageService.uploadImage(image4);
      return resImg4.secure_url;
    }
    return '';
  }, []);
  const updloadImage5 = useCallback(async image5 => {
    if (image5 && image5 !== '') {
      const resImg5 = await UploadImageService.uploadImage(image5);
      return resImg5.secure_url;
    }
    return '';
  }, []);

  const customUseGetUser = useCallback(async () => {
    const res = await UsersService.getUser();
    return res;
  }, []);

  const handleUpdatePictures = useCallback(async formValues => {
    setPicturesLoading(true);
    try {
      const res = await UploadImageService.uploadImages({
        image1: await updloadImage1(formValues.image1),
        image2: await updloadImage2(formValues.image2),
        image3: await updloadImage3(formValues.image3),
        image4: await updloadImage4(formValues.image4),
        image5: await updloadImage5(formValues.image5),
      });
      if (res.success) {
        const userUpdated = await customUseGetUser();
        setUser(userUpdated);
        setPicturesLoading(false);
      } else {
        setPicturesLoading(false);
      }
    } catch (error: any) {
      setPicturesLoading(false);
      message.error(error.response?.data?.statusText || t('global:generalResponse'), 4);
    }
  }, []);

  const sidebarInfos = useMemo(
    () => [
      {
        icon: <SettingOutlined className={cn('infos-item-icon', 'bg-icon-success')} />,
        itemEntitled: t('myAccount:settings'),
      },
      {
        icon: <BellOutlined className={cn('infos-item-icon', 'bg-icon-warning')} />,
        itemEntitled: 'Notifications',
        counter: countNotifications,
      },
      {
        icon: <LockOutlined className={cn('infos-item-icon', 'bg-icon-purple')} />,
        itemEntitled: t('myAccount:security'),
      },
      {
        icon: <PictureOutlined className={cn('infos-item-icon', 'bg-icon-success')} />,
        itemEntitled: 'Mes photos',
      },
    ],
    [countNotifications],
  );

  const handleChangeAccountView = useCallback((index: number) => {
    if (!accountViews?.length) return;
    setSelectedItem(index);
  }, []);

  const accountViews = useMemo(
    () => [
      { view: <AccountForm user={user as any} handleSubmit={handleUpdateUserInfos} /> },
      {
        view: <NotificationLayout notifications={notifications} />,
      },
      { view: <UpdatePasswordForm handleSubmit={handleUpdatePassword} /> },
      {
        view: (
          <UploadPicturesForm
            handleSubmit={handleUpdatePictures}
            image1={user?.images?.image1 ? (user?.images?.image1 as string) : ''}
            image2={user?.images?.image2 ? (user?.images?.image2 as string) : ''}
            image3={user?.images?.image3 ? (user?.images?.image3 as string) : ''}
            image4={user?.images?.image4 ? (user?.images?.image4 as string) : ''}
            image5={user?.images?.image5 ? (user?.images?.image5 as string) : ''}
            loader={picturesLoading}
          />
        ),
      },
    ],
    [user, notifications, picturesLoading],
  );
  const setDefaultAccountView = useCallback(() => {
    if (!accountViews[0]) return;
    setSelectedItem(0);
  }, [accountViews]);

  const getInitialUser = useCallback(async () => {
    try {
      setLoader(true);
      const res = await customUseGetUser();
      setUser(res);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      message.error("Impossible de récupérer les informations de l'utilisateur, Prière réessayer plus tard", 3);
    }
  }, []);

  useEffect(() => {
    getInitialUser();
    setDefaultAccountView();
    getNotifications();
  }, []);

  return (
    <div className="myAccount">
      <Row justify="center">
        <Col lg={14} xs={24} sm={24}>
          <div className="myAccount-wrapper">
            <div className="myAccount-wrapper-header">
              <UserOutlined className="myAccount-wrapper-header-icon" />
              <Title
                className="myAccount-wrapper-header-title"
                level={2}
                weight={FontWeight.SEMIBOLD}
                variant={TextVariants.PRIMARY}
              >
                {t('myAccount:myAccount')}
              </Title>
            </div>
            {accountViews[selectedItem].view}
          </div>
        </Col>
        <Col lg={10} xs={24} sm={24}>
          <div className="myAccount-sidebar-container">
            <Sidebar
              selectedItem={selectedItem}
              handleChangeAccountView={handleChangeAccountView}
              user={user as any}
              infos={sidebarInfos}
              loader={loader}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MyAccount;
