import React from 'react';
import cn from 'classnames';

import { Title } from '~/components/Typo/Typo';
import Card, { CardProps } from '~/components/Card/Card';
import { FontWeight } from '~/theme/constants';

import './ServicesCard.scss';

interface ServicesCardProps extends CardProps {
  image: string;
  title: string;
}

const ServicesCard: React.FC<ServicesCardProps> = ({ image, title, className, rounded = true }) => (
  <Card rounded={rounded} className={cn(className, 'servicesCard')}>
    <img className="servicesCard-image" src={image} />
    <Title className="serviceCard-title" weight={FontWeight.BOLD}>
      {title}
    </Title>
  </Card>
);

export default ServicesCard;
