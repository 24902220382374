import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import React, { createRef, ReactElement, useCallback, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import NavigationButton from '~/components/ItemsSlider/NavigationButton/NavigationButton';
import Dots from '~/components/ItemsSlider/Dots/Dots';

import './ItemsSlider.scss';

interface SlideProps {
  items: ReactElement[];
}

const SlideItems: React.FC<SlideProps> = ({ items }) => {
  const [displayLeftNavButton, setDisplayLeftNavButton] = useState(false);
  const [displayRightNavButton, setDisplayRightNavButton] = useState(true);
  const [captionIndex, setCaptionIndex] = useState(1);
  const itemListRef = createRef<HTMLDivElement>();

  const handleNavigationClick = useCallback(
    (direction: 'left' | 'right') => {
      if (itemListRef.current) {
        const { clientWidth, scrollLeft, scrollWidth } = itemListRef.current;
        const left = direction === 'left' ? -1 * clientWidth : clientWidth;
        const afterScroll = scrollLeft + left;

        itemListRef.current.scrollBy({
          behavior: 'smooth',
          left,
        });

        if (afterScroll <= 0) {
          setDisplayLeftNavButton(false);
        } else if (!displayLeftNavButton) {
          setDisplayLeftNavButton(true);
        }

        if (afterScroll + clientWidth >= scrollWidth) {
          setDisplayRightNavButton(false);
        } else if (!displayRightNavButton) {
          setDisplayRightNavButton(true);
        }
      }
    },
    [itemListRef, displayLeftNavButton, displayRightNavButton],
  );

  const handleChangeIndex = useCallback(event => {
    setCaptionIndex(event + 1);
  }, []);

  return (
    <div className="items-slider">
      <div className="items-slider-wrapper">
        {displayLeftNavButton && (
          <NavigationButton className="left-navigation-button" onClick={handleNavigationClick.bind(this, 'left')}>
            <CaretLeftOutlined className="icon" />
          </NavigationButton>
        )}
        <div className="content-wrapper" ref={itemListRef}>
          {items}
        </div>
        {displayRightNavButton && (
          <NavigationButton className="right-navigation-button" onClick={handleNavigationClick.bind(this, 'right')}>
            <CaretRightOutlined className="icon" />
          </NavigationButton>
        )}
      </div>
      <div className="items-slider-wrapper-mobile">
        <div className="item-slider-wrapper-mobile-caption">{captionIndex + ' / ' + items.length}</div>
        <SwipeableViews enableMouseEvents onChangeIndex={handleChangeIndex}>
          {items}
        </SwipeableViews>
      </div>
      <Dots
        onClickLeft={handleNavigationClick.bind(this, 'left')}
        onClickRight={handleNavigationClick.bind(this, 'right')}
      />
    </div>
  );
};
export default SlideItems;
