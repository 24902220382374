import React, { ReactElement, useEffect, useState } from 'react';
import _ from 'lodash';

interface TimerProps {
  secondsProp: number;
  onEnd?: () => void;
  component: (seconds: number) => ReactElement;
}

const Timer: React.FC<TimerProps> = ({ secondsProp, onEnd, component }) => {
  const [seconds, setSeconds] = useState(secondsProp);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds !== 0) {
        const newValue = seconds - 1;
        setSeconds(newValue);

        if (newValue === 0 && _.isFunction(onEnd)) {
          onEnd();
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return component(seconds);
};

export default Timer;
