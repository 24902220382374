import { Col, Row } from 'antd';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

import SearchCraftmanForm from '~/components/forms/SearchCraftmanForm/SearchCraftmanForm';
import Loader from '~/components/loaders/Loader/Loader';
import { Title } from '~/components/Typo/Typo';
import CraftmenDirectoryCard from '~/containers/CraftmenDirectoryCard/CraftmenDirectoryCard';
import { CraftsmenService } from '~/services/CraftsmenService';
import { FontWeight, TextVariants } from '~/theme/constants';

import './FindCraftman.scss';

const FindCraftman: React.FC = () => {
  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);
  const [craftsmen, setCraftsmen] = useState<ReactElement[]>([]);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<number>(0);

  const getCraftsmen = useCallback(async () => {
    try {
      const res = await CraftsmenService.getLastRegisteredCraftsmen(100);
      if (res.success) {
        const data = res.craftsmen;
        const slice = data.slice(offset, offset + perPage);
        const items = slice.map((item, idx) => (
          <Col key={idx} lg={8} sm={12} xs={24}>
            <CraftmenDirectoryCard
              id={item._id}
              fullName={`${item.firstName} ${item.lastName}`}
              avatar={item.picture as any}
              trades={[item.job as any]}
              localisation={`Abidjan, ${item.town}`}
              biography={item.biography as any}
            />
          </Col>
        ));
        setCraftsmen(items);
        setResult(res.count as number);
        setPageCount(Math.ceil(data.length / perPage));
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
    }
  }, [offset, perPage]);

  const searchCraftman = useCallback(
    async formValues => {
      setLoading(true);
      try {
        const res = await CraftsmenService.searchCraftsmen(formValues.location, formValues.job);
        if (res.success) {
          const data = res.craftsmen;
          const slice = data.slice(offset, offset + perPage);
          const items = slice.map((item, idx) => (
            <Col key={idx} lg={8} sm={12} xs={24}>
              <CraftmenDirectoryCard
                id={item._id}
                fullName={`${item.firstName} ${item.lastName}`}
                avatar={item.picture as any}
                trades={[item.job as any]}
                localisation={`Abidjan, ${item.town}`}
                biography={item.biography as any}
              />
            </Col>
          ));
          setCraftsmen(items);
          setResult(res.count as number);
          setPageCount(Math.ceil(data.length / perPage));
          setLoading(false);
        }
      } catch (error: any) {
        setLoading(false);
      }
    },
    [offset, perPage],
  );

  const handlePageClick = useCallback(
    e => {
      const selectedPage = e.selected;
      setOffset(selectedPage + 1);
    },
    [setOffset],
  );

  useEffect(() => {
    getCraftsmen();
  }, [offset, setOffset]);

  return (
    <div className="find-craftman">
      <div className="find-craftman-header">
        <Title className="find-craftman-title" weight={FontWeight.BOLD} variant={TextVariants.PRIMARY}>
          {t('findCraftman:findCraftmanTitle')}
        </Title>
      </div>
      <div className="find-craftman-content">
        <SearchCraftmanForm handleSubmit={searchCraftman} />
        <div className="find-craftman-craftmenDirectory-wrapper">
          {loading ? (
            <Row justify="center">
              <Loader />
            </Row>
          ) : (
            <>
              {result > 0 ? (
                <Title level={4} weight={FontWeight.SEMIBOLD} variant={TextVariants.PRIMARY}>{`${result} ${t(
                  'findCraftman:searchCraftmanForm:craftsmenFound',
                )}`}</Title>
              ) : (
                <Title level={4} weight={FontWeight.SEMIBOLD} variant={TextVariants.PRIMARY}>{`${t(
                  'findCraftman:searchCraftmanForm:noResultsFound',
                )}`}</Title>
              )}
              <Row className="find-craftman-craftmenDirectory-cards">{craftsmen}</Row>
              {result > 0 && (
                <Row justify="center" className="mt-8">
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={perPage}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                  />
                </Row>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FindCraftman;
