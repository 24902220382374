import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';

import Content from '~/containers/Content/Content';
import Header from '~/containers/Header/Header';
import Footer from '~/containers/Footer/Footer';
import Home from '~/pages/home/Home';
import Login from '~/pages/login/Login';
import CraftmenDirectory from '~/pages/craftmenDirectory/CraftmenDirectory';
import {
  CONTACT_ROUTE,
  CRAFTMEN_DIRECTORY,
  CRAFTMEN_PROFILE,
  FIND_CRAFTMAN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  MY_ACCOUNT_ROUTE,
  OUR_SERVICES_ROUTE,
  REGISTER_ROUTE,
  SEARCH_CRAFTSMAN,
} from '~/routing/routes';
import mainBanner from '~/assets/images/main-banner.png';
import FindCraftman from '~/pages/findCraftman/FindCraftman';
import Register from '~/pages/register/Register';
import MyAccount from '~/pages/myAccount/MyAccount';
import PrivateRoute from '~/routing/PrivateRoute';
import GoogleAnalytics from '~/helpers/GoogleAnalytics/GoogleAnalytics';
import { SessionsService } from '~/services/SessionsService';
import CraftmenProfile from '~/pages/craftmenProfile/CraftmenProfile';
import SearchCraftsman from '~/pages/searchCraftsman/SearchCraftsman';

const Router: React.FC = () => {
  useEffect(() => {
    GoogleAnalytics(location.pathname);
  }, [location.pathname]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={LOGIN_ROUTE()} component={Login} />
        <Route exact path={REGISTER_ROUTE()} component={Register} />
        <Route exact path={FORGOT_PASSWORD_ROUTE()} render={() => 'Forgot password'} />
        <Route path="/" component={RoutesWithLayout} />
        <RoutesWithLayout />
      </Switch>
    </BrowserRouter>
  );
};

const RoutesWithLayout: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const getAuthRoute = useCallback(() => {
    if (SessionsService.isLoggedIn()) {
      return ['myAccount', MY_ACCOUNT_ROUTE()];
    }
    return ['login', LOGIN_ROUTE()];
  }, [MY_ACCOUNT_ROUTE(), LOGIN_ROUTE()]);

  const menuItems = useMemo(
    () =>
      [
        ['home', HOME_ROUTE()],
        ['ourServices', OUR_SERVICES_ROUTE()],
        ['findCraftman', FIND_CRAFTMAN_ROUTE()],
        ['contact', CONTACT_ROUTE()],
        getAuthRoute(),
      ].map(([translationKey, route], idx) => ({
        key: idx,
        label: t(`header:navigation:${translationKey}`),
        link: route,
        active: location.pathname === route,
      })),
    [location.pathname, getAuthRoute()],
  );

  return (
    <Route
      path="/"
      render={() => (
        <>
          <Header
            items={menuItems}
            banner={mainBanner}
            title={t('header:findIdealCraftman')}
            subtitle={t('header:subtitle')}
          />
          <Content>
            <Switch>
              <Route exact path={HOME_ROUTE()} component={Home} />
              <Route exact path={FIND_CRAFTMAN_ROUTE()} component={FindCraftman} />
              <Route exact path={CONTACT_ROUTE()} />
              <Route exact path={CRAFTMEN_DIRECTORY()} component={CraftmenDirectory} />
              <Route exact path={CRAFTMEN_PROFILE()} component={CraftmenProfile} />
              <Route exact path={SEARCH_CRAFTSMAN()} component={SearchCraftsman} />
              <PrivateRoute exact path={MY_ACCOUNT_ROUTE()} component={MyAccount} />
            </Switch>
          </Content>
          <Footer />
        </>
      )}
    />
  );
};

export default Router;
