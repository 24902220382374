import { Menu } from 'antd';
import React, { HTMLProps, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { LoadingContext } from '~/contexts/LoaderContext';
import { getLanguage, Language, languages } from '~/locales/languages';

import './LanguageSelect.scss';

const { SubMenu } = Menu;

interface LanguageSelectProps extends HTMLProps<HTMLDivElement> {}

const LanguageSelect: React.FC<LanguageSelectProps> = ({ className }) => {
  const { i18n } = useTranslation();
  const { startLoading } = useContext(LoadingContext);

  const currentLanguage: Language = useMemo(() => getLanguage(i18n.language), [i18n.language]);

  const handleLanguageChange = useCallback(
    async lang => {
      startLoading();
      await i18n.changeLanguage(lang);
      window.location.reload();
    },
    [startLoading],
  );
  return (
    <div className={cn(className, 'language-select')}>
      <img className="flag-icon" src={currentLanguage.icon} alt="active language icon" />
      <div className="select-container">
        <Menu mode="inline">
          <SubMenu key="SubMenu">
            {languages
              .filter(l => l.label !== currentLanguage.label)
              .map(({ label, icon }, index) => (
                <img
                  key={index}
                  onClick={() => handleLanguageChange(label)}
                  className="flag-icon"
                  src={icon}
                  alt={label}
                />
              ))}
          </SubMenu>
        </Menu>
      </div>
    </div>
  );
};

export default LanguageSelect;
